header.mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 99;
}

header.mainHeader {
    padding: 10px 0;
    height: 90px;
    /* box-shadow: 0 3px 6px #00000029; */
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}

.accTimeCnt {
    flex-direction: column;
}

.accTimeCnt p span {
    color: var(--primColor);
}

.topHeader {
    height: 44px;
    border-bottom: 1px solid #e6e6e6;
}

.topHeader ul,
.mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.topHeader ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--textColor);
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
}

.topHeader .material-icons,
.topHeader li img {
    padding-inline-end: 10px;
}

.topHeader .linkDivider {
    padding: 0 10px;
}

header .headLt {
    margin-inline-end: 20px;
}

header .headLt img {
    max-width: 200px;
    width: 100%;
    height: auto;
    object-fit: scale-down;
}

.languageMenu img {
    padding-inline-end: 10px;
}

header .menuRtOtr {
    width: 100%;
}

header .switchUserDD .customSelect {
    margin-bottom: 0;
}

header .switchUserDD .customSelect .MuiOutlinedInput-root {
    height: 45px;
}

header .switchUserDD .customSelect .MuiInput-underline:after,
header .switchUserDD .customSelect .MuiInput-underline::before {
    display: none;
}

header .headRt ul li:not(:last-child) {
    margin-inline-end: 15px;
}

header .headRt ul li.headCart {
    margin-inline-end: 0;
}

header .headRt ul li.headCart > button .MuiBadge-badge {
    color: var(--primColor);
    background-color: #ffffff !important;
    border: 1px solid var(--primColor);
}

header .headRt ul li button.MuiButton-text .MuiButton-label {
    color: var(--textColor);
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
}

header .headRt ul li a {
    color: var(--textColor);
    font-size: 14px;
    font-weight: 600;
    padding: 0 0.5rem;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

header .headRt ul li a.active {
    color: var(--primColor);
}

header .headRt ul .languageSwitch {
    display: inline-flex;
    align-items: center;
}

header .headRt ul .languageSwitch a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

header .headRt ul .languageSwitch a img {
    margin-inline-end: 10px;
}

header .headRt ul .languageSwitch .material-icons {
    font-size: 32px;
    color: #838383;
}

header .headLt .ASlogo {
    max-width: 150px;
    display: block;
}

header .headLt .headerSearchForm {
    border: none;
    background: #e9e9e9;
    border-radius: 25px;
    height: 45px;
    max-width: 370px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-inline-start: 25px;
}

.headRt .headerSearchForm {
    border: 1px solid #ddd;
}

header .headLt .headerSearchForm input {
    border: 1px solid #ddd;
    margin-inline-end: 0;
    height: inherit;
    background: inherit;
    border-radius: 25px;
    /* border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    padding: 12px 25px 12px 25px;
    color: #333;
    width: -webkit-fill-available;
}

header .headLt .headerSearchForm button {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primColor);
    border-radius: 50%;
    width: 42px;
    height: 42px;
    margin-inline-end: 1px;
    min-width: fit-content;
}

header .headLt .headerSearchForm button .material-icons {
    width: 24px;
    height: 24px;
    overflow: hidden;
}

header .headLt .headerSearchForm input:focus,
header .headLt .headerSearchForm button:focus {
    border: none;
    box-shadow: none;
}

header .headLt .headerSearchForm input::placeholder {
    color: #333;
}

/* header .headRt ul li a:hover {
    color: #212121;
} */

header .headRt ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

header .headRt ul li a.active:after {
    width: 100%;
    left: 0;
}

header .headRt ul li a:hover:after {
    width: 100%;
    left: 0;
}

header .headRt ul li span.material-icons {
    /* color: var(--accentColor); */
    font-size: 25px;
}

.respNav,
.respNavBtn {
    display: none !important;
}

.searchSlide {
    padding-top: 20px;
}

.searchSlide h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
}

.searchSlide h3 span {
    margin-inline-start: 10px;
    font-size: 15px;
    text-transform: capitalize;
    color: #808080;
    font-weight: 600;
}

.searchSlide .searchHeaderInput {
    height: 50px;
}

.searchSlide .searchHeaderInput input {
    height: inherit;
}

.searchSlide .searchHeaderInput input:focus {
    box-shadow: none;
    border-color: var(--primColor);
}

.searchSlide .searchHeaderInput .input-group-prepend {
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    z-index: 100;
    background: #fff;
    color: var(--primColor);
    padding: 0;
    border: none;
}

.searchSlide .searchHeaderInput .input-group-prepend .MuiButton-label {
    height: 100%;
}

.searchSlide .searchHeaderInput .input-group-prepend .input-group-text {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    border: none;
    display: flex;
    justify-content: center;
    background: #fff;
    color: var(--primColor);
}

.popularCatg .catgType label h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    width: 100%;
    color: #313131;
    text-align: center;
    margin-top: 10px;
    line-height: 22px;
}

.popularCatg .catgType .iconCnt {
    background: #efefef;
    padding: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popularCatg .catgType label span {
    font-size: 32px;
    color: #848484;
}

.popularCatg .catgType label .MuiListItem-button {
    height: 100%;
    flex-wrap: wrap;
}

.searchSlide h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
    color: #717171;
    margin-top: 30px;
}

.popularCatg .catgType {
    width: 33%;
    height: 90px;
    margin-bottom: 15px;
}

.popularCatg .catgType label {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px dashed #777;
}

.popularCatg .catgType input:checked ~ label {
    background: var(--primColor);
    color: #fff;
    border: 1px dashed var(--primColor);
}

.popularCatg .catgType input:checked ~ label h4 {
    color: #fff;
}

.popularCatg .catgType input:checked ~ label span {
    color: #ffc6bb;
}

.popularCatg .catgType input:checked ~ label .iconCnt {
    background: #ca2d0f;
}

.popularCatg .catgType label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-inline-end: 10px;
    -webkit-transition: 250ms all ease-in-out;
    -o-transition: 250ms all ease-in-out;
    transition: 250ms all ease-in-out;
}

.headerDrawer .languageSwitch a > img {
    padding-inline-end: 12px;
}

.respHeaderSearch .headerSearchForm {
    flex-wrap: nowrap;
    padding: 10px 15px;
}

.respHeaderSearch .headerSearchForm input {
    width: 100%;
    width: 100%;
    border-radius: 25px;
    background: var(--accentColor);
    height: 50px;
    padding-inline-start: 20px;
    border-color: transparent;
}

.respHeaderSearch .headerSearchForm button {
    min-width: 50px;
    background: #e0e0e080;
    margin-inline-start: 5px;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    color: #919191;
}

.headerDropMenu {
    padding-left: 0;
}
.headerDropMenu hr {
    /* margin: 0em; */
    margin: auto;
    max-width: 80%;
}

.headerDropMenu .MuiButtonBase-root {
    color: #6f6f6f;
    /* padding: 10px 15px; */
}
.headerDropMenu .MuiListItem-root {
    padding-top: 4px;
    padding-bottom: 4px;
}

/* 
.headerDropMenu .MuiPopover-paper {
    top: 100px !important;
    right: 30px;
    left: initial !important;
}

.headerDropMenu.rtl .MuiPopover-paper {
    right: initial;
    left: 30px !important;
} */

.langDropMenu .MuiPopover-paper {
    top: 40px !important;
    left: initial !important;
    right: 30px;
    width: 150px;
}

.langDropMenu.rtl .MuiPopover-paper {
    right: initial;
    left: 30px !important;
}

.headerDropMenu .MuiButtonBase-root {
    color: #6f6f6f;
    padding: 10px 15px;
}

.headerDropMenu .MuiButtonBase-root:hover {
    color: #313131;
}

.headerDropMenu .MuiButtonBase-root .material-icons {
    padding-inline-end: 10px;
}

.headerDropMenu .MuiPopover-paper .MuiBadge-badge {
    right: 15px;
    border: 1px solid #fff;
}

.headerDropMenu .MuiButtonBase-root .MuiBadge-root .MuiBadge-badge {
    right: 10px;
}

.headRt.respNav .navRespLinks.wrappfixBtn .MuiListItem-root {
    width: auto;
}

.headRt.respNav .navRespLinks.wrappfixBtn {
    position: sticky;
    bottom: 0;
    background: #ebebeb;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .headerSearchForm .searchBtn {
    min-width: 50px;
    /* background: #3cc634; */
}

header .headerSearchForm .searchBtn object {
    pointer-events: none;
}

header .headerSearchForm .form-control:focus {
    box-shadow: unset;
}

header .headerSearchForm .form-control::placeholder {
    color: var(--accentColor);
}

header .headerSearchForm .form-control {
    border: 0;
    outline: 0;
    box-shadow: unset;
    width: 100%;
}

/* header .headerSearchForm .inputSch::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;
    width: 2px;
    background: rgba(161, 161, 161, 0.5);
} */

header .headerSearchForm .inputSch::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;
    width: 2px;
    background: rgba(161, 161, 161, 0.5);
}

header .headerSearchForm .inputSch {
    position: relative;
    width: 511px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}
header .headerSearchForm .inputSch header .headerSearchForm {
    border: 1px solid rgba(188, 188, 188, 0.5);
    padding: 3px 12px;
}

header .headerSearchForm .cameraIco {
    padding-left: 6px;
    padding-right: 6px;
    min-width: 50px;
    cursor: pointer;
    line-height: 38px;
    color: #919191 !important;
    border-radius: 3px;
    font-size: 25px;
}

header .headerSearchForm .headerAllCateGrp button {
    text-transform: capitalize;
}

header .headerMenuList li:not(:last-child) {
    margin-inline-end: auto !important;
}

header .dropLst {
    border: 1px solid rgba(161, 161, 161, 0.5);
    padding: 10px 8px;
    border-radius: 0;
}

header .watLst {
    border: 1px solid var(--accentColor);
    width: 40px;
    height: 40px;
    border-radius: 100px;
    min-width: 40px;
}

header .headerMenuList {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    justify-content: flex-end;
    padding: 0 24px !important;
}

.headerBottom .MuiButtonBase-root {
    width: auto;
    white-space: nowrap;
    padding-left: 0;
    padding-right: 0px;
    font-weight: 400;
    text-transform: none;
    background: transparent !important;
    color: black;
    font-size: 16px;
    font-weight: 500;
}
.headerBottom .MuiButtonBase-root:hover .MuiTypography-displayBlock {
    color: var(--primColor);
}

.headerBottom .MuiButtonBase-root .active {
    color: var(--primColor);
}

.headerBottom .headerSubMenuList {
    display: flex;
    align-items: center;
    gap: 50px;
}

.headerBottom .rttENd a {
    color: rgb(97 171 255);
    /* color: var(--colorBlue); */
    text-transform: capitalize;
}

.headerBottom {
    /* display: grid; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 6px 0;
    margin: 0;
    /* max-width: none !important; */
    /* margin-left: 0 !important; */
    /* margin-right: 0 !important; */
    grid-template-columns: 1fr auto;
    gap: 80px;
    background: white;
    /* border-bottom: 1px solid #e6e6e6; */
}

/* .headerBottom .megaMenu .menuTitle:hover {
    background-color: transparent;
} */

.headerSubMenuList .headerBottom .megaMenu .menuTitle {
    /* color: var(--textColor); */
    color: #232323;
    text-transform: capitalize;
    padding-right: 0;
}

.headerBottom .MuiTypography-displayBlock {
    color: #232323;
    font-weight: 500;
}

.headerBottom .active .MuiTypography-displayBlock {
    color: #3cc634;
}

.level1Menu.popperMenu .MuiPaper-root {
    background: #fff;
    margin-top: 8px;
}

.level1Menu.popperMenu .noRecords {
    color: var(--textColor);
}

.level1Menu.popperMenu .menuList {
    color: var(--textColor);
}

.MuiPaper-root .menuList {
    padding: 0;
}

.scannerBox {
    text-align: center;
    padding: 25px;
}

.scannerBox .uploadgalleryImg .cancelIco {
    position: absolute;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    right: 0;
    cursor: pointer;
    color: #ff6c6c;
}

.scannerBox .uploImg:hover .cancelIco {
    opacity: 1;
}

.scannerBox .uploadgalleryImg {
    position: relative;
}

.scannerBox .uploadgalleryImg .scanInput {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 0;
}

.scannerBox .scanedImg {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background: #ededed;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.customDialog.scanDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 400px;
}

/* RESPONSIVE */

@media (max-width: 1500px) {
    header .headerSearchForm .inputSch {
        width: 300px;
    }
}

@media (max-width: 1300px) {
    header .headRt ul li:not(:last-child) {
        margin-inline-end: 10px;
    }

    header .headRt ul li a {
        font-size: 15px;
    }

    header .headerSearchForm .inputSch {
        width: 200px;
    }
}

@media (max-width: 1200px) {
    header .headLt .headerSearchForm {
        max-width: 200px;
    }

    header .headLt .headerSearchForm input {
        width: 100%;
    }

    header .headLt .headerSearchForm input::placeholder {
        font-size: 14px;
    }
}

@media (max-width: 1145px) {
    header .headRt ul li:not(:last-child) {
        margin-inline-end: 5px;
    }

    header .headRt ul li a {
        font-size: 15px;
    }

    header .headRt li > button {
        min-width: max-content;
        margin-inline-end: 5px;
    }
}

@media (max-width: 1024px) {
    .filterPanelToggle .MuiDrawer-paper {
        padding: 15px;
    }
    .headerBottom .rttENd a,
    .headerBottom .MuiTypography-displayBlock {
        font-size: 14px;
    }
    header .headLt img {
        max-width: fit-content;
        width: 100%;
        height: 70px;
        object-fit: scale-down;
    }

    .deskNav {
        display: none !important;
    }

    .respNav {
        display: block !important;
    }

    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: #000 !important;
    }

    .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-weight: 600;
        /* justify-content: space-between; */
    }

    .headRt.respNav .MuiListItem-root .languageSwitch {
        padding-inline-start: 15px;
    }

    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root .switchUserDD .customSelect {
        margin-bottom: 0 !important;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        padding-inline-start: 0px;
        color: #a6a6a6;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .headRt.respNav a,
    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-weight: 600;
        flex-wrap: wrap;
    }

    .headRt.respNav a .hnContact,
    .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .headRt.respNav a.active,
    .headRt.respNav .navRespLinks a.active,
    .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .headRt.respNav .naLogoHead {
        padding: 3px 0px 3px 24px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
    }

    .headRt.respNav .naLogoHead img {
        width: 100%;
        object-fit: scale-down;
        width: 120px;
        height: 70px;
    }

    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .mobShopCurrLocation {
        padding-bottom: 0 !important;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    /* .headRt.respNav button {
        justify-content: space-between;
    } */

    .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }

    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }

    header {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 100;
        box-shadow: 0px 3px 10px #cccccc61;
    }
}

@media (max-width: 992px) {
    .respHeaderSearch .headerSearchForm input {
        border-color: #acacac;
        background-color: #efefef;
    }
    .headerBottom .MuiButtonBase-root {
        font-size: 13px;
    }
    .headerBottom .headerSubMenuList {
        gap: 10px;
    }
}

@media (max-width: 767px) {
    .headerBottom .MuiButtonBase-root {
        font-size: 12px;
    }
    .headerBottom .rttENd a,
    .headerBottom .MuiTypography-displayBlock {
        font-size: 12px;
    }
    .langDropMenu.rtl .MuiPopover-paper {
        top: unset !important;
    }

    .searchSlide h3,
    .searchSlide h4 {
        font-size: 16px;
    }

    .searchSlide h3 span {
        margin-inline-start: 10px;
        font-size: 13px;
    }

    .popularCatg .catgType label h4 {
        font-size: 14px;
    }

    header.mainHeader {
        height: 70px;
    }

    header.mainHeader .headLt img {
        max-width: 110px;
    }

    header .headRt ul li a {
        padding: 0 10px;
    }

    .customDialog.scanDialog .MuiPaper-root.MuiDialog-paper {
        min-width: 98vw;
    }
}

/* ******* */
