/* .megaMenu .menuTitle {
    color: #fff;
} */

.level1Menu .MuiPaper-root {
    background: #352414;
    margin-top: 20px;
    height: max-content;
    min-height: 45px;
    padding: 15px;
    border-radius: 0;
}

.level1Menu.fullWidth .MuiPaper-root {
    width: 100%;
    left: 0 !important;
    max-width: calc(100% - 16px);
    max-height: 60vh;
}

.level1Menu.fullWidth .MuiPaper-root::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
}
.level1Menu.fullWidth .MuiPaper-root::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.level1Menu.fullWidth .MuiPaper-root:hover::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}

.level1Menu .menuList {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    color: #fff;
}

.level1Menu .menuList .MuiListItem-root {
    font-size: 15px;
    opacity: 0.8;
}

.level1Menu .menuList .MuiListItem-root:hover {
    opacity: 1;
}

.level1Menu .noRecords {
    margin: 0;
    text-align: center;
    color: #fff;
}

@media (max-width: 600px) {
    .level1Menu.fullWidth .MuiPaper-root {
        max-height: 100%;
    }

    .level1Menu .menuList {
        grid-template-columns: 1fr;
    }
}
