.productView .pvLt,
.productView .pvRt {
    width: 100%;
}

.productView .pvLt .image-gallery {
    position: sticky;
    top: 0;
}

.productView .image-gallery-thumbnails-container {
    text-align: start;
}

.productView .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: none;
}

.productView .image-gallery-icon:hover {
    color: var(--primColor);
}

.productView .image-gallery-thumbnail {
    border: 3px solid #d6d4d4;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.productView .image-gallery-thumbnail:not(:last-child) {
    margin-right: 8px;
}

.productView .image-gallery-thumbnail.active,
.productView .image-gallery-thumbnail:hover,
.productView .image-gallery-thumbnail:focus {
    outline: none;
    border: 3px solid var(--colorBrown);
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.productView .pvTimerBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.productView .pvTimerBox > * {
    margin-bottom: 0;
}

.productView .pvTimerBox label {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    font-size: 15px;
}
.productView .pvClosedTimer label {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    margin-right: 0.24rem;
    font-size: 15px;
}

.productView .pvTimerBox span {
    color: #db3e3e;
}

.productView .pvTimerBox h4 {
    color: #db3e3e;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.3px;
}

.productView .pvActBtn .pabChild {
    text-align: center;
}

.productView .pvActBtn {
    background: #f8f8f8;
    padding: 15px 10px;
}

.productView .pvBidBox {
    width: 100%;
    margin: 20px auto 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.productView .pvBidBox .biddingCnt > div {
    margin: 0;
    width: 100%;
}

.productView .pvBidBox .biddingCnt > div:only-child {
    width: 100%;
}

.productView .pvBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productView .pvBidBox .customInput,
.productView .customInput .MuiTextField-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root input {
    height: 65px;
    border-radius: 1px !important;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    margin-top: 5px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 0px;
}

.productView .pvBidBox .tertButton {
    width: 100%;
    margin-inline-start: 10px;
}

.productView .pvBidBox .tertButton,
.productView .pvBidBox .tertButton button {
    height: 50px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton,
.productView .pvBidBox .primButton button,
.productView .pvBidBox .secButton button {
    height: 50px;
    font-weight: 700;
}

.productView .pvBidBox form {
    width: 60%;
    margin: 0 auto;
}

.productView .pvBidBox form:not(:nth-child(1)) {
    margin-top: 22px;
}

.productView .pvActBtn .pabChild label {
    font-size: 14px;
    color: #373737;
}

.productView .pvActBtn .pabChild h4 {
    font-size: 18px;
    color: #373737;
    font-weight: 700;
}

.productView .pvActBtn .pabChild.pvCurrentBid h4 {
    color: var(--primColor);
}

.productView .pvBidHistBtn {
    text-transform: initial;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    font-size: 16px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    width: fit-content;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
}

.productView .pvBidHistBtn .MuiButton-label .material-icons {
    padding-inline-end: 10px;
}

.productView .pvBidBox > div {
    margin: 0;
    width: 49%;
    margin-inline-end: 10px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton {
    width: 49%;
    margin: 0 auto;
}

.productView .pvProductInfo {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;
    justify-content: space-between;
}

.productView .pvProductInfo .addWatch {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: fit-content;
    text-transform: capitalize;
    line-height: 123.4%;
    /* 19.744px */
    letter-spacing: 0.32px;
}

.productView .pvProductInfo h2 {
    margin: 0;
    font-weight: 700;
    color: #000;
    padding: 0;
    text-align: left;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 123.4%;
    text-transform: capitalize;
    letter-spacing: 0.56px;
    margin-bottom: 5px;
}

.productView .pvProductDesc label {
    font-size: 15px;
    color: #373737;
    font-weight: 600;
    margin: 0;
    padding-inline-end: 10px;
}

.productView .pvProductDesc h6 {
    font-size: 15px;
    color: #646464;
    font-weight: 400;
    margin: 0;
    display: inline;
}

.productView .pvProductDesc .pvDescChild {
    margin-bottom: 10px;
}

.productView .cartHead {
    font-size: 24px;
    color: #353535;
    font-weight: 700;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
}

.productView .MuiPaper-root.MuiAccordion-root {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.productView .MuiButtonBase-root.MuiAccordionSummary-root {
    margin-bottom: 0;
}

.productView .MuiAccordionDetails-root {
    font-size: 15px;
}

.productView .accTitle {
    margin-bottom: 0;
    font-weight: 600;
}

.productView .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 75px;
    object-fit: contain;
    border-radius: 0px;
    width: 100%;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.productView .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 350px;
    object-fit: contain;
}

.productView .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto;
}

.productView .pvBidStatus {
    padding: 10px 0;
    background: #fff;
    position: sticky;
    width: 100%;
    top: -20px;
    z-index: 10;
}

.productView .pvBidStatus h4 {
    padding: 15px;
    color: #fff;
    height: 45px;
    width: 100%;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.25px;
    border-radius: 5px;
}

.productView .pvBidStatus h4.winning {
    background: #06a2b4;
}

.productView .pvBidStatus h4.won {
    background: #06b473;
}

.productView .pvBidStatus h4.outbid {
    background: #ff9472;
}

.productView .pvBidStatus h4.lost {
    background: #ff7272;
}

.productView {
    padding-top: 20px;
}

.closeSlider {
    position: absolute;
    top: 0px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    min-width: initial;
}

.pvProductInfo .googleSearch {
    display: flex;
    align-items: center;
    padding-left: 4px;
}

.pv-comments-wrapper {
    margin-top: 25px;
}

.pv-comments-wrapper .property-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.pv-comments-wrapper .property-head h4 {
    font-size: 16px;
    font-weight: 500;
    margin: auto 0;
}

.pv-comments-wrapper .askQuesBtn {
    width: max-content;
    min-width: 150px;
    margin-left: auto;
    margin-bottom: 20px;
}

.commentsCard {
    border-top: 1px solid #ededed;
    padding: 20px 10px 15px;
    margin: 10px 0;
}

.commentsCard .commentQuesLabel {
    background: #a3deff;

    padding: 2px 15px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
    border-radius: 40px;
}

.commentsCard .commentQues {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
    width: 100%;
    word-break: break-word;
    line-height: 24px;
}

.commentsCard .commentAns {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    margin-top: 5px;
    width: 100%;
    word-break: break-word;
    line-height: 24px;
}

.commentsCard .dateStamp {
    margin: 0;
    font-size: 12px;
    color: #333;
    margin-bottom: 10px;
}

.commentsCard .commentAnsLabel {
    background: #b3e9b2;

    padding: 2px 15px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
    border-radius: 40px;
}

.commentsCard .seeMoreAns {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 15px;
}

.commentsCard .seeMoreAns .MuiButton-label {
    text-transform: initial;
    font-size: 12px;
    color: #000;
    font-weight: 500;
}

/* New chamges Producvt View */
.productViewLayoutCut {
    display: grid;
    grid-template-columns: min(523px) 1fr;
    gap: 47px;
    margin-bottom: 10px;
}

.rightDrawer .productViewLayoutCut {
    grid-template-columns: 1fr;
}

.ViewFullDetails {
    display: none;
}

.rightDrawer .ViewFullDetails {
    display: block;
}

.rightDrawer .ViewFullDetails .primButton {
    text-align: right;
}

.rightDrawer .ViewFullDetails .primButton button {
    width: fit-content;
}

.productView .productNavi {
    margin-bottom: 20px;
}

.productView .productNavi .goBack {
    color: #232323;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    cursor: pointer;
}

.productView .image-gallery-slide-wrapper {
    margin-bottom: 21px;
    border: 1px solid #d6d4d4;
    padding: 25px;
    height: max(400px);
}

.productView .image-gallery-content.fullscreen {
    bottom: 0;
    height: calc(100vh - 110px);
}

.productView .image-gallery-content.fullscreen .image-gallery-slide-wrapper {
    height: 100%;
}

.productView .image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
    padding: 0;
}

.productViewLayoutCut .productDeatailsView {
    margin-bottom: 25px;
}

.productViewLayoutCut .productDeatailsView .lotID {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
    margin-bottom: 8px;
    text-align: left;
}

.productViewLayoutCut .pvTabs .tPanel p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.3px;
    text-align: left;
}

.productViewLayoutCut .pvTabs .tPanel {
    padding: 20px 0;
}

.productViewLayoutCut .pvTabs .MuiTabs-indicator {
    background-color: var(--primColor);
}

.productViewLayoutCut .pvTabs .MuiAppBar-root {
    background-color: #fff;
    color: #000;
    font-size: 16px;
    box-shadow: unset;
    border-bottom: 1px solid #e2e1e1;
}

.productDeatailsView .locaProduct .txt {
    text-decoration: underline;
}

.productDeatailsView .productLabel {
    background-color: #eefaf0;
    color: #348d3e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    padding: 6px 16px;
    width: fit-content;
}

.productDeatailsView .locaProduct {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #2f6baf;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.3px;
    margin-bottom: 15px;
    cursor: pointer;
    width: fit-content;
}

.productDeatailsView .biddingConteiner .bidInformaiton .pvBidsandOffers .pvhardBid p {
    color: #545454;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.3px;
    margin: 0;
}

.productDeatailsView .biddingConteiner .bidInformaiton .pvBidsandOffers .pvOfferZone p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
}

.productDeatailsView
    .biddingConteiner
    .bidInformaiton
    .pvBidsandOffers
    .pvOfferZone
    p:nth-child(1) {
    color: #545454;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-decoration-line: strikethrough;
    margin-bottom: 8px;
}

.productDeatailsView .biddingConteiner .bidInformaiton .pvBidsandOffers .pvOfferZone {
    text-align: left;
}

.productDeatailsView .biddingConteiner .bidInformaiton .pvBidsandOffers .pvhardBid h3 {
    color: #101913;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-bottom: 0px;
}

.productDeatailsView .biddingConteiner .bidInformaiton .pvBidsandOffers .pvhardBid {
    text-align: left;
}

.productDeatailsView .biddingConteiner .bidInformaiton .pvBidsandOffers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 20px;
}

.productDeatailsView .pvGetPro label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
    letter-spacing: 0.3px;
    margin-right: 4px;
}
.pvFloorLabel {
    margin-bottom: 0 !important;
}

.productDeatailsView .pvGetPro .pvDescriptionCnt {
    margin-bottom: 6px;
}
.productDeatailsView .pvGetPro .pvDescriptionCnt label {
    margin-bottom: 0px;
    line-height: inherit;
    margin-right: 4px;
}

.productDeatailsView .pvGetPro .txt {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
}

.productDeatailsView .googleSearch {
    color: #2f6baf;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration-line: unset;
    -moz-text-decoration-line: unset;
}

.productDeatailsView .pvGetPro {
    text-align: left;
}

.productDeatailsView .pvGetPro.description {
    margin-top: 12px;
    margin-bottom: 12px;
}

.productDeatailsView .biddingConteiner .bidInformaiton .pvTimerContain {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 15px;
    margin-bottom: 19px;
}

.productDeatailsView .biddingConteiner .bidForm .biddingCnt .biddingCnt,
.productDeatailsView .biddingConteiner .bidForm .biddingCnt {
    width: 100%;
}

.productDeatailsView .biddingConteiner {
    display: grid;
    grid-template-columns: 1fr min(277px);
    gap: 40px;
    margin-top: 16px;
}

.productView .productDeatailsView .pvBidBox form {
    width: 100%;
}

.productView .productDeatailsView .pvBidBox .MuiFormHelperText-root {
    text-align: left;
}

.productView .productDeatailsView .bidInstruction {
    color: #232323;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
}

.productView .productDeatailsView .bidInstruction2 {
    color: #232323;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    margin-top: 16px;
}

.productView .productDeatailsView .pvBidBox {
    margin: 0;
}

.biddingModalSet .biddingField .secButton .MuiButtonBase-root {
    height: 56px;
}

.biddingModalSet .biddingField {
    gap: 10px;
}

.biddingModalSet .productImg figure img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    padding: 15px;
}

.biddingModalSet .productImg figure {
    border: 0.5px solid #d6d4d4;
    background: #fff;
    height: 245px;
}
.biddingModalSet {
    display: grid;
    grid-template-columns: max(225px) 1fr;
    gap: 35px;
}

.biddingModalSet .bidCnter .curBid {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 0.3px;
}

.biddingModalSet .bidCnter .para {
    color: #232323;
    margin-top: 15px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
}

.biddingModalSet .modalTimer {
    color: #db3e3e;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.3px;
}

.biddingModalSet .bidCnter {
}

.customDialog.biddingModal .MuiPaper-root.MuiDialog-paper {
    max-width: 650px;
}

.biddingModalSet .biddingField .customInput {
    margin-top: 0 !important;
    width: max(150px);
}

.pvDescAllCnt {
    display: -webkit-box;
    webkitboxorient: vertical;
    overflow: hidden;
    textoverflow: ellipsis;
}

.pvDescriptionReadBtn {
    text-decoration: none;
    color: var(--primColor);
    cursor: pointer;
    margin-top: 4px;
}
.pvDescriptionReadBtn:hover {
    text-decoration: underline;
}

.locationBadgeCnt {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.biddingEndsCnt {
    margin-top: 19px;
}
.addNotesBtnCnt {
    padding: 0 !important;
}
.addNotesBtnCnt:hover,
.addNotesBtnCnt .MuiButton-label:hover {
    background: transparent;
}
.addNotesBtnCnt .MuiButton-label {
    display: flex;
    align-items: center;
    gap: 8px;
}
.addNotesBtnCnt span:nth-child(2) {
    text-transform: none;
    font-size: 16px;
}
.addNotesBtnCnt .material-icons {
    font-size: 21px;
}

.productViewDrawer.productViewLayoutCut {
    gap: 16px;
}

.custom-gallery .image-gallery-bullets .image-gallery-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(37, 47, 61, 0.4);
    box-shadow: none !important;
    border-radius: 50%;
}

.custom-gallery .image-gallery-bullets .image-gallery-bullet.active {
    background-color: rgb(37, 47, 61);
}

.custom-gallery .image-gallery-left-nav,
.custom-gallery .image-gallery-right-nav {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.custom-gallery .image-gallery-left-nav::before,
.custom-gallery .image-gallery-right-nav::before {
    font-size: 24px;
}

/* responsive css */

@media (max-width: 1366px) {
    .productViewLayoutCut .pvTabs .MuiAppBar-root .MuiTab-root {
        min-width: 75px;
    }
}

@media (max-width: 1280px) {
    .productViewLayoutCut {
        grid-template-columns: min(500px) 1fr;
    }
    .productDeatailsView .biddingConteiner {
        gap: 20px;
    }
    .productView .pvBidBox .biddingCnt > div {
        width: 100%;
    }
}

@media (max-width: 1240px) {
    .productDeatailsView .biddingConteiner {
        grid-template-columns: 1fr;
    }
    .productViewLayoutCut {
        grid-template-columns: min(350px) calc(100% - 400px);
    }
}

@media (max-width: 1024px) {
    .productView .pvBidBox form {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .productViewLayoutCut {
        grid-template-columns: 1fr;
    }
    .rightDrawer .productViewLayoutCut {
        display: block;
    }
    .rightDrawer .productViewLayoutCut .MuiTabs-fixed {
        overflow: auto !important;
    }
}

@media (max-width: 500px) {
    .productView .pvProductInfo h2 {
        font-size: 16px;
    }
    .productView .pvBidBox {
        width: 100%;
    }

    .productView .cartHead {
        font-size: 18px;
        color: #353535;
        font-weight: 700;
        margin: 20px 0 10px 0;
        text-transform: uppercase;
    }

    .productView .pvBidBox form {
        width: 100%;
    }

    .productView .pvBidBox form:nth-child(2) {
        margin-top: 15px;
    }

    .productView .pvBidBox .biddingCnt > div {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .productView .image-gallery-slide-wrapper {
        height: max(320px);
    }
    .productView .image-gallery-content .image-gallery-slide .image-gallery-image {
        min-height: 240px;
        max-height: 240px;
    }
    .productViewLayoutCut .productDeatailsView {
        margin-bottom: 16px;
    }
    .productDeatailsView .biddingConteiner .bidInformaiton .pvBidsandOffers .pvhardBid h3 {
        font-size: 24px;
    }

    .productView .pvBidBox .biddingCnt {
        flex-wrap: wrap;
    }

    .productView .pvBidBox .biddingCnt .customInput {
        /* width: 100%; */
        margin-bottom: 15px;
    }

    /* .productView .pvBidBox .biddingCnt .primButton,
    .productView .pvBidBox .biddingCnt .secButton {
        width: 100%;
    } */
    .similarItem .pclImg img {
        height: 90px;
    }

    .productView .pvBidStatus h4 {
        font-size: 13px;
    }
}
