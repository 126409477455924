.customDialog h5 {
    font-size: 18px;
    margin-bottom: 20px;
}

.customDialog .MuiDialogContent-root {
    padding-bottom: 20px;
}

.customDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 300px;
}

.customDialog .MuiDialogTitle-root {
    padding-bottom: 10px;
}

.customDialog .MuiDialogTitle-root h2 {
    text-transform: capitalize;
}

.customDialog.fileUploadModal .MuiPaper-root.MuiDialog-paper {
    min-width: 650px;
}

.conditionModal .conditionsDialog {
    position: relative;
    justify-content: center !important;
    border-bottom: 1px solid #f2f2f2;
}
.conditionModal .conditionsDialog .ssmHead {
    position: absolute;
    right: 24px;
}

.conditionModal.customDialog .MuiDialogTitle-root {
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
}

.conditionModal .ssmHead svg {
    width: 13px;
}
