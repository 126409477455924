.favoriteCheck {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.115);
    /* border-top-left-radius: 3px; */
    position: relative;
}
/* .favoriteCheck .material-icons {
    color: black;
} */

.favoriteCheck input {
    display: none;
}

.favoriteCheck label {
    color: black;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    margin: 0;
    margin-top: 5px;
    user-select: none;
}

.favoriteCheck label:hover {
    color: grey;
}

.favoriteCheck label::selection {
    color: none;
    background: transparent;
}

.favoriteCheck label::moz-selection {
    color: none;
    background: transparent;
}

.favoriteCheck input:checked .material-icons {
    color: var(--primColor);
}
