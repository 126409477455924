.checkout {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.checkout .checkoutHead {
    font-size: 16px;
    color: #0e131f;
    font-weight: 700;
    margin: 0;
    text-align: start;
    margin-bottom: 10px;
    margin-top: 30px;
}

.checkout .checkoutHead ~ span {
    padding-inline-start: 10px;
    display: inline-block;
}

.checkout .creditApply .customCheckbox .MuiFormControlLabel-root {
    margin-right: 0;
}

.checkout .checkoutSecAction {
    background: #fff;
    padding: 15px;
    margin-top: 20px;
}

.checkout .checkoutSecAction label {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    padding-inline-end: 10px;
}
.errorMsg {
    color: rgb(240, 49, 49);
}
.checkout .checkoutSecAction h6 {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    display: inline;
}

.checkout .selectDate {
    width: 200px;
    height: 100px;
}

.checkout .selectDate label {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 80px;
    border: 1px dashed #777;
}

.checkout .selectDate input:checked ~ label {
    background: #ddf5eb;
    color: #06b473;
    border: 1px solid #8cddbf;
    position: relative;
}

.checkout .selectDate input:checked ~ label:after {
    content: '\f058';
    position: absolute;
    top: 50%;
    right: 15px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #06b473;
    transform: translateY(-50%);
}

.checkout .selectDate label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-inline-end: 10px;
    -webkit-transition: 250ms all ease-in-out;
    -o-transition: 250ms all ease-in-out;
    transition: 250ms all ease-in-out;
}

.checkout .selectDate label p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 2px;
}

.checkout .selectDate label span {
    margin: 0;
    font-size: 14px;
}

.checkout .selectDate label .MuiListItem-button {
    height: 100%;
    flex-wrap: wrap;
}

.checkout .chCnt.timeSlots .selectDate label p {
    text-align: center;
}

.checkout .schedule,
.checkout .payment,
.checkout .review,
.checkout .pickupAddress,
.checkout .billingInfo {
    margin-bottom: 20px;
    background: #fff;
    padding: 0;
}

.checkout .review:not(:last-child) {
    /* border-bottom: 1px solid #e4e4e4; */
    padding-bottom: 25px;
}

.checkout .pickupAddress {
    text-align: start;
}

.checkout .pickupAddress h6 {
    font-size: 14px;
    color: #0e131f;
    margin-top: 10px;
}

.checkout .pickupAddress h5 {
    font-size: 15px;
    color: var(--secColor);
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 20px;
}

.checkout .schedule .scheduleError {
    font-size: 13px;
    color: #f44336;
    text-align: start;
    margin-top: 2px;
}

.checkout .payment .cardDetails {
    margin-top: 15px;
}

.checkout .payment .cardDetails h4 {
    text-align: start;
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 15px 0;
}

.checkout .selectTime .customSelect,
.checkout .paymentMethod .customSelect {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: #fff;
    margin-top: 20px;
    min-width: 200px;
}

.checkout .selectTime > label,
.checkout .paymentMethod > label {
    margin-inline-end: 20px;
}

.checkout .payment .paymentMethod {
    background: #fff;
    margin: 20px 0 10px 0;
    padding: 20px 0;
}

.checkout .schedule .selectTime {
    background: #fff;
    margin: 10px 0;
    padding: 20px 0 0 0;
}

.checkout .payment .paymentMethod .customSelect,
.checkout .schedule .selectTime .customSelect {
    margin: 0;
}

.checkout .selectCard label {
    margin: 0;
}

.checkout .selectCard p {
    margin: 0;
    text-align: start;
}

.checkout .selectCard table {
    width: 100%;
}

.checkout .selectCard table tr {
    border-bottom: 5px solid whitesmoke;
    border: 1px dashed #b6b6b6;
}

.checkout .selectCard table td {
    padding: 10px;
}

.checkout .checkoutLt {
    width: 68%;
}

.checkout .checkoutLt .isMultiCardSelection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.checkout .checkoutLt .subHead {
    margin: 0;
    text-transform: initial;
    margin-inline-end: 15px;
    font-size: 16px;
}

.checkout .checkoutLt .cartItem {
    margin-bottom: 0;
}

.checkout .checkoutLt .noMarginBottom .customInput {
    margin-bottom: 0;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-label {
    width: max-content;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-root {
    margin: 0;
    margin-inline-end: 15px;
}

.checkout .checkoutLt .isMultiCardSelection .MuiButtonBase-root.MuiListItem-root {
    width: max-content;
    margin-inline-end: 20px;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormGroup-root {
    flex-wrap: nowrap;
}

.billingAddress {
    text-align: start;
}

.billingAddress h4 {
    font-size: 16px;
    font-weight: 600;
}

.billingAddress p {
    margin-bottom: 0;
    margin-top: 5px;
}

.billingInfo button {
    color: var(--primColor);
    border-color: var(--primColor);
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControl-root,
.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-root {
    width: 100%;
}

.checkout .checkoutRt {
    width: 30%;
    text-align: start;
}

.checkout .checkoutRt .orderSummary {
    background: #fff;
    padding: 20px;
    position: sticky;
    top: 15px;
    border-radius: 5px;
    border: 1px dashed #a6adb4;
}

.checkout .checkoutRt .primButton {
    margin-bottom: 0px;
    margin-top: 0px;
}

.checkout .checkoutRt .customSelect {
    margin-top: 20px;
}

.checkout .checkoutRt h3 {
    font-size: 20px;
    color: var(--secColor);
    font-weight: 700;
    margin-bottom: 20px;
}

.checkout .checkoutRt > p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: center;
    align-self: center;
}

.checkout .checkoutRt > p,
.checkout .checkoutRt p label {
    font-size: 16px;
    color: #111111;
    font-weight: 600;
    margin-bottom: 0px;
}

.checkout .checkoutRt p {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout .checkoutRt h4 {
    font-size: 18px;
    color: var(--primColor);
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: center;
    align-self: center;
    margin-bottom: 0;
}

.checkout .customInput,
.checkout .customSelect {
    margin-bottom: 20px;
}

.checkout .orderSummarySection .primButton {
    margin-top: 10px;
}

.checkout .orderSummarySection h6 {
    font-size: 16px;
    color: #111111;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

.checkout .orderSummarySection h6 span {
    padding-inline-start: 5px;
}

.checkout .orderSummarySection .osBack {
    width: 100%;
    margin-top: 10px;
}

.checkout .checkoutRt h4 label {
    margin-bottom: 0;
}

.existingAppointmentBanner {
    background: #d8f1ff;
    padding: 30px 15px;
    position: relative;
    overflow: hidden;
}

.existingAppointmentBanner > div {
    z-index: 1;
}

.existingAppointmentBanner .material-icons {
    position: absolute;
    font-size: 80px;
    color: #afe3ff;
    top: -10px;
    left: -30px;
}

.existingAppointmentBanner h2 {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: start;
}

.existingAppointmentBanner h4 {
    font-size: 1rem;
    text-align: start;
    margin-bottom: 0;
}

.existingAppointmentBanner .primButton {
    margin-bottom: 0;
}

.partialPaymentToggle .MuiFormControlLabel-root {
    margin-inline-end: 0;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.partialPaymentToggle .MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: var(--secColor);
    font-weight: 600;
}

.checkout .cartSkeletonBody {
    margin: 60px 0;
}

.checkout .cartSkeletonBody .csbItem .listViewSkeleton {
    background: #fff;
    grid-template-columns: 1fr 3fr 1.8fr;
}

.checkout .cartSkeletonBody .csbItem .listViewSkeleton > div:nth-child(2) {
    margin: 0 40px 0 20px;
}

.billingInfo > .CardList {
    padding: 0;
}

.CardList .material-icons {
    padding-right: 15px;
}

.CardList .savedAddressCard {
    width: calc(100% - 12%);
    min-width: calc(100% - 12%);
}

.CardList .savedAddressCard .actionBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.CardList .savedAddressCard .actionBtn .MuiButton-label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #bababa;
}

.CardList .savedAddressCard .cardDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.CardList .savedAddressCard .cardDetails h6 {
    width: 100%;
}

.savedAddressCard.bank {
    border: none !important;
    background: transparent !important;
}

.CardList {
    align-items: flex-start;
    min-width: 100%;
    background: #eaf2ff;
}

.checkoutAddBtn {
    padding: 10px 5px;
    float: right;
    margin: 15px 0;
}

.billingInfo .checkoutSavedCard {
    background: #f7f7f7;
}

.billingInfo .checkoutSavedCard:not(:last-child) {
    margin-bottom: 2px;
}

.billingInfo .checkoutSavedCard .billingSavedCard .actionBtn {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}

.billingInfo .checkoutSavedCard .billingSavedCard {
    width: 100%;
}

.billingInfo .savedAddress {
    display: grid;
    text-align: left;
    margin-top: 25px;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
}

.billingInfo .savedAddressCard {
    width: 100%;
    height: auto;
    background: rgb(234 242 255);
    border: 1px solid #e0e0e0;
    padding: 15px 20px;
    border-radius: 5px;
    position: relative;
}

.billingInfo .savedAddressCard .addrLabel {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #6c6b6b;
    margin-bottom: 5px;
}

.billingInfo .savedAddressCard.addNew {
    background: #fff;
    border: 2px dashed #dfdfdf;
}

.billingInfo .savedAddressCard h4,
.billingInfo .savedAddressCard address,
.billingInfo .savedCardsCard h4 {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
}

.billingInfo .savedAddressCard address {
    margin: 0;
}

.billingInfo .savedAddressCard .ssActBtn .MuiButton-label {
    text-decoration: underline;
}

.billingInfo > a,
.billingInfo > a {
    font-size: 16px;
    color: #000;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 600;
    color: var(--primColor);
}

.billingInfo .savedAddressCard .dfltCard {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: center;
    background: var(--primColor);
    color: white;
    border-radius: 100px 0 0 100px;
    padding: 5px 10px;
    font-size: 14px;
}

@media (max-width: 1024px) {
    .checkoutCnt {
        flex-wrap: wrap;
        /* flex-direction: column-reverse; */
    }

    .checkout .checkoutLt,
    .checkout .checkoutRt {
        width: 100%;
    }

    .checkout .checkoutRt .orderSummary {
        position: static;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .checkout .checkoutRt .orderSummary .primButton button {
        width: 200px;
        margin-top: 20px;
        float: right;
    }

    .checkoutSecAction {
        display: none !important;
    }
}

@media (max-width: 845px) {
    .existingAppointmentBanner .primButton {
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .existingAppointmentBanner {
        padding: 20px 15px;
    }

    .existingAppointmentBanner h2 {
        font-size: 1.2rem;
    }

    .existingAppointmentBanner h4 {
        font-size: 0.9rem;
    }

    .chCnt {
        flex-wrap: wrap;
    }

    .checkout .selectDate {
        margin-bottom: 15px;
        margin-inline-end: 15px;
        width: 45%;
        height: 70px;
    }

    .checkout .selectDate label {
        margin-inline-end: 0;
    }

    .checkout .selectDate label p {
        width: 100%;
    }

    .checkout .selectDate input:checked ~ label:after {
        top: 20%;
    }

    .checkout .checkoutRt .orderSummary .primButton button {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .checkout .checkoutLt .noMarginBottom .customInput {
        margin-bottom: 30px;
    }

    .checkout .payment {
        padding-bottom: 0;
    }

    .checkout .schedule .selectTime {
        padding-top: 0;
    }
}

@media (max-width: 500px) {
    .checkout {
        padding-top: 10px;
    }

    .checkout .selectDate {
        width: 100%;
        margin-inline-end: 0px;
        height: max-content;
    }

    .checkout .selectDate label p {
        width: max-content;
    }

    .checkout .selectDate label span {
        margin-inline-start: auto;
    }

    .checkout .checkoutLt .noMarginBottom .customInput {
        margin-bottom: 25px;
    }

    .checkout .checkoutLt .subHead {
        font-size: 14px;
    }

    .checkout .checkoutHead {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .checkout .payment .paymentMethod {
        margin: 10px 0 10px 0;
        padding: 10px 0;
    }

    .checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-label {
        font-size: 14px;
    }

    .checkout .checkoutRt .orderSummary .primButton button {
        width: 100%;
    }
}

@media (max-width: 440px) {
    .checkout .payment .paymentMethod,
    .checkout .schedule .selectTime {
        flex-wrap: wrap;
    }

    .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .checkout .selectTime .customSelect,
    .checkout .paymentMethod .customSelect {
        width: 100%;
    }

    .checkout .schedule .selectTime .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 15px;
    }
}

@media (max-width: 400px) {
    .checkout .checkoutLt .isMultiCardSelection {
        flex-wrap: wrap;
    }

    .checkout .checkoutLt .isMultiCardSelection .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
