.fullScreenImageModalInner .fullScreenImage {
    width: 100%;
    height: calc(100vh - 20px);
    object-fit: contain;
    margin: 0 auto;
    text-align: center;
    cursor: move;
}

.fullScreenImageModal .fsCloseIcon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0;
    background: #0000001c;
    position: absolute;
    top: 20px;
    right: 20px;
    color: #e8e8e8;
    z-index: 1;
}

.fullScreenImageModal .MuiDialog-container {
    background: #73737324;
    backdrop-filter: blur(20px);
}

.fullScreenImageModal .MuiDialog-paper {
    width: 100%;
    margin: 0 auto;
    height: calc(100% - 20px);
    box-shadow: none;
    background: transparent;
    overflow: hidden;
}

.fullScreenImageModal .fsAct {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.fullScreenImageModal .fsAct button {
    min-width: initial;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #0000001c;
    color: #ffffff;
}

.fullScreenImageModal .fsAct button.Mui-disabled {
    color: #868686;
}

.fullScreenImageModal .imageCount {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 1;
    background: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    box-shadow: 0px 3px 10px #33333347;
}

.fullScreenImageModal .fullScreenImageTools {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    background: #fff;
    padding: 0px 8px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    box-shadow: 0px 3px 10px #33333347;
}

.fullScreenImageModal .fullScreenImageTools button.imageIcon {
    min-width: initial;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.react-transform-wrapper {
    margin: auto !important;
}
