footer {
    text-align: start;
    color: var(--textColor);
    background-color: #fff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    margin-top: auto;
}

/* footer .footLogo img:first-child {
    margin-inline-end: 30px;
    width: 160px;
    object-fit: contain;
} */

footer .MuiDivider-root {
    margin: 32px auto;
    max-width: 1600px;
}

.Footinstagram {
    /* background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    ); */

    background: radial-gradient(
        circle at 30% 107%,
        rgba(253, 244, 151, 1) 0%,
        rgba(253, 244, 151) 0%,
        rgba(253, 89, 73, 1) 19%,
        rgba(214, 36, 159, 1) 60%,
        rgba(40, 90, 235, 1) 90%
    );

    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 12px;
    border-radius: 6px;
    align-items: center;
    display: flex;
    width: fit-content;
    text-decoration: none;
}

.Footinstagram:hover {
    text-decoration: none;
}

.Footinstagram span {
    font-size: 12px;
}

footer .socialContact {
    display: flex;
    gap: 10px;
}

footer .footLinks .socialContact a img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

footer .footLogo img {
    width: 200px;
    object-fit: contain;
    margin-right: auto;
}

footer h2 {
    font-weight: bold;
    margin-bottom: 27px;
}

footer ul {
    padding-inline-start: 0;
    margin-bottom: 0;
    list-style-type: none;
}

footer ul li {
    padding-bottom: 0px;
    line-height: normal;
}

footer ul li a {
    color: var(--textColor);
    position: relative;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
}

footer .upArrow {
    text-align: right;
}

footer .upArrow span {
    background-color: transparent;
    width: 47px;
    height: 47px;
    flex-shrink: 0;
    display: flex;
    z-index: 999;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px solid #a5afbc;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

footer .footLinks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

footer .footLinks ul {
    display: grid;
    gap: 15px;
    align-content: baseline;
}

footer .footLinks ul li a {
    color: rgba(35, 35, 35, 0.75);
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: unset;
}

footer .footLinks ul li:first-child {
    color: rgba(35, 35, 35, 0.75);
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    padding: 0;
    margin-bottom: 7px;
}

footer .footSocial .worldIcon {
    font-size: 30px;
}

footer .footSocial img {
    width: 25px;
    height: 25px;
}

footer ul li a:hover {
    text-decoration: none;
    color: var(--textColor);
}

footer ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--textColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

footer ul li a:hover:after {
    width: 100%;
    left: 0;
}

footer .footMisc .footSocial li:not(:last-child) {
    margin-inline-end: 30px;
}

footer .footMisc .footSubscribe form {
    background: #3a658e;
    border-radius: 5px;
    height: auto;
    flex-wrap: nowrap;
}

footer .footMisc .footSubscribe form {
    margin-bottom: 5px;
}

footer .footMisc .footSubscribe form .customInput {
    margin-bottom: 0;
}

footer .footMisc .footSubscribe form .customInput {
    background: inherit;
    border: none;
    color: var(--textColor);
    height: inherit;
}

.footSubscribe .MuiFormLabel-root {
    color: var(--textColor);
}

footer .footMisc .footSubscribe form .subsInp {
    align-self: stretch;
}

footer .footMisc .footSubscribe form button {
    height: auto;
    align-self: stretch;
}

footer .footMisc .footSubscribe form .MuiFormHelperText-root.Mui-error {
    color: #000000;
    background: #ff616163;
    margin: 0;
    padding: 3px 10px;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:before {
    border-bottom: transparent;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:after {
    border-bottom: 1px solid var(--primColor);
}

footer .footMisc .footSubscribe form input:focus {
    box-shadow: none;
    border: none;
}

footer .footMisc .footSubscribe form input::placeholder {
    color: var(--textColor);
}

footer .footMisc .footSubscribe form button {
    color: var(--textColor);
    display: flex;
}

footer .footMisc .footSubscribe small {
    color: var(--textColor);
}

footer .footCopyright {
    background-color: #23303e;
    padding: 4px 0;
    margin: 0;
    text-align: center;
    color: white;
    text-align: center;
    font-size: 12px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 163%;
    margin-top: 32px;
}

footer .businessHrs p {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    position: relative;
    margin-right: 10px;
}

footer .businessHrs {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 3px 0;
}

footer .businessHrs p:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 1px;
    background-color: #7c7c7c;
}

footer .footCopyright div {
    display: inline;
    margin-right: 6px;
}

footer .poweredBy {
    color: var(--textColor);
    margin: 15px 0 0 0;
    font-size: 14px;
    text-align: right;
    font-weight: normal;
}

footer .hashTag {
    color: var(--textColor);
    margin: 0 0 15px 0;
    font-size: 14px;
    text-align: right;
    font-weight: normal;
}

footer .footCnt .logodivi img {
    margin-bottom: 20px;
}

footer .footCnt .footLogo img {
    object-fit: scale-down;
    margin: 0;
    width: 100%;
    height: 100%;
}
footer .footCnt .footLogo {
    max-width: 150px;
    display: block;
    margin-bottom: 15px;
}

footer .footCnt .logodivi .footCopyright {
    text-align: left;
    color: rgba(35, 35, 35, 0.75);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 163%;
    margin-top: 0;
}

footer .footCnt {
    /* padding-top: 60px; */
    display: grid;
    grid-template-columns: min(25%) auto;
    gap: 15px;
}

footer .footLogo {
    margin-bottom: 40px;
}

@media (max-width: 1200px) {
    .footCnt .footLogo {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0 auto 40px;
    }
}

@media (max-width: 1024px) {
    footer .footLinks ul {
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    .footCnt .footLinks,
    .footCnt .footMisc {
        width: 100%;
    }
    footer .businessHrs {
        flex-wrap: wrap;
    }

    footer .footMisc .footSubscribe form .subsInp {
        width: 90%;
    }

    footer .footMisc .footSubscribe button {
        min-width: auto;
    }

    footer .footSocial {
        justify-content: center !important;
        margin: 10px auto;
    }

    footer .footCopyright {
        margin-top: 25px;
        text-align: center !important;
    }

    footer .footMisc .footSubscribe form {
        flex-wrap: nowrap;
    }

    footer .hashTag {
        margin-top: 10px;
    }

    footer .hashTag,
    footer .poweredBy {
        text-align: center;
    }

    footer .footLinks ul {
        justify-content: space-around;
        margin-bottom: 20px;
    }

    footer .footLogo img {
        margin-left: auto;
    }
}

@media (max-width: 600px) {
    footer .footCnt {
        grid-template-columns: 1fr;
    }
    footer .footCnt .logodivi .footCopyright {
        text-align: left !important;
    }
    footer .footLinks ul {
        justify-content: start;
    }
}
@media (max-width: 445px) {
    footer ul li a {
        font-size: 13px;
    }

    footer .footMisc .footSubscribe small {
        text-align: center;
        width: 100%;
        display: block;
    }

    footer .footLinks ul:not(:last-child) {
        margin-inline-end: 35px;
    }
}
