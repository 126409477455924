.filterPanel {
    position: sticky;
    top: 15px;
}

/* GRID */
.productCardGrid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
    height: 100%;
    text-align: start;
    background: #fff;
    padding: 14px 16px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    /* padding: 0; */
    /* box-shadow: unset; */
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.endingSoonCarousel .productCardGrid {
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
}

.productCardGrid .pcgImg {
    width: 100%;
    height: 220px;
    position: relative;
}

.productCardGrid .pcgImg .favoriteCheck,
.productCardList .pclImg .favoriteCheck {
    position: absolute;
    top: 0;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgba(255, 70, 70, 10%);
    z-index: 10;
}

.productCardGrid .pcgImg .favoriteCheck label,
.productCardList .pclImg .favoriteCheck label {
    font-size: 14px;
    color: var(--textColor);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.productCardGrid .pcgImg .favoriteCheck input:checked + label,
.productCardList .pclImg .favoriteCheck input:checked + label {
    color: #ee5600;
}

.productCardGrid .primButton.small button,
.productCardGrid .secButton.small button {
    font-size: 18px;
    min-width: max-content;
    width: 100%;
    text-transform: capitalize;
    padding: 5px;
    font-weight: 600;
    background: var(--secColor);
    border: none;
    color: white;
    border-radius: 0px;
    height: min(47px);
}

.productCardGrid .productWinningStatus,
.productCardList .productWinningStatus {
    padding: 10px 30px;
    color: #fff;
    height: 30px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 5px;
    width: auto;
    width: max-content;
    line-height: 1;
}

.productCardGrid .productWinningStatus.winning,
.productCardList .productWinningStatus.winning {
    background: #06a2b4;
}

.productCardGrid .productWinningStatus.won,
.productCardList .productWinningStatus.won {
    background: #06b473;
}

.productCardGrid .productWinningStatus.outbid,
.productCardList .productWinningStatus.outbid {
    background: #ff9472;
}

.productCardGrid .productWinningStatus.lost,
.productCardList .productWinningStatus.lost {
    background: #ff7272;
}

.productCardGrid .reserveNotMet,
.productCardList .reserveNotMet {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 55px;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgba(114, 142, 255, 90%);
    color: #fff;
    cursor: pointer;
}

.productCardGrid .productWinningStatus:nth-child(1) {
    top: 20px;
}

.productCardGrid .pcgImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.productCardGrid .gridProdTitle {
    font-size: 16px;
    color: #231f20;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    min-height: 44px;
}

.productCardGrid .gridLotInfo .gridDivider {
    padding: 0 10px;
}

.productCardGrid .gridLotDetails p {
    font-size: 13px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 6ex;
    text-align: center;
    width: 100%;
}

.productCardGrid .gridTimer .material-icons {
    padding-inline-end: 10px;
}

.productCardGrid .gridTimer {
    width: 100%;
    padding: 8px 15px;
    text-align: center;
    margin-bottom: 20px;
}

/* .productCardGrid .gridTimer h6 {
    margin: 0;
    font-size: 12px;
    padding-inline-end: 5px;
} */

.productCardGrid .gridTimer p {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    padding-left: 10px;
}

.productCardGrid .gridTimer .grdTmrIcon {
    position: relative;
    height: 30px;
}

.productCardGrid .gridTimer .grdTmrIcon img {
    background: white;
    position: absolute;
    margin-top: -22px;
    padding: 8px;
    border-radius: 100px;
    left: 43%;
}

.productCardGrid .gridBidInfo {
    margin-top: 5px;
    height: auto;
    flex-direction: column-reverse !important;
}
.productCardGrid .gridBidInfo > p {
    font-weight: 500;
    margin-bottom: 0;
}

.productCardGrid .gridBidInfo h6 {
    margin-bottom: 0;
    color: var(--accentColor);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
}

/* .productCardGrid .gridBidInfo p {
    margin-bottom: 0;
    color: var(--textColor);
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
} */

.productCardGrid .gridBidInfo .price {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start !important;
    margin-bottom: 0;
}

.productCardGrid .gridBidInfo .price p {
    margin-bottom: 0;
    color: var(--textColor);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
}
.productCardGrid .gridBidInfo .price span {
    margin-bottom: 0;
    color: var(--textColor);
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
}

.productCardGrid .gridBidInfo p span.gridDivider {
    padding: 0 15px;
}

.productCardGrid .gridTimer p span {
    padding-inline-end: 5px;
}

.productCardGrid .gridBidBox {
    margin-top: 15px;
    height: 7ex;
}

.productCardGrid .gridBidBox .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-size: 13px;
}

.productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
    width: 100%;
    margin-inline-start: 0;
}

.productCardGrid .gridBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
    font-size: 12px;
}

.productCardGrid .gridBidBox .biddingCnt .primButton,
.productCardGrid .gridBidBox .biddingCnt .secButton {
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .primButton:only-child,
.productCardGrid .gridBidBox .biddingCnt .secButton:only-child {
    width: 100%;
}

.productCardList .customInput .MuiFormControl-root.MuiTextField-root input,
.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root input {
    background: rgb(230, 230, 230);
    border-radius: 1px !important;
}

.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root {
    border: none;
}

.productCardGrid .customInput .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

/* .productCardGrid .gridBidBox .biddingCnt.beforeLogin .primButton {
    width: 100%;
} */

/* GRID END */

/* AUCTION CARD */

.auctionCard {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 3px 20px #0000001f;
}

.auctionCard .auctionCardLt {
    width: 70%;
    padding: 10px;
}

.auctionCard .acImg {
    width: 100%;
    max-width: 250px;
    min-width: 250px;
    height: 200px;
    position: relative;
    margin-inline-end: 20px;
}

.auctionCard .acImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.auctionCard .acContent {
    margin-inline-end: 15px;
    text-align: start;
}

.auctionCard .acActBtn {
    width: 263px;
    border-left: 1px solid #ebebeb;
}

.auctionCard .acContent h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #231f20;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-transform: uppercase;
}

.auctionCard .acContent p {
    font-size: 15px;
    font-weight: 400;
    color: #646464;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.auctionCard .acContent .acAddress {
    height: 11ex;
}

.auctionCard .acContent .acStartDate {
    color: #333;
    font-weight: 700;
}

.auctionCard .acContent .acStartDate span {
    padding-inline-end: 10px;
    color: #333;
    width: 100px;
    display: inline-block;
}

.auctionCard .acTimer .primButton {
    width: 90%;
    margin-top: 10px;
}

.auctionCard .acTimer .primButton button {
    width: 100%;
}

/* .auctionCard .acActBtn .acTimer h6 {
    display: flex;
    align-items: center;
    color: #2e2e2e;
    font-weight: 700;
    margin-bottom: 0;
    width: 100%;
    justify-content: center;
    text-transform: capitalize;
    padding: 13px;
    font-size: 13px;
} */

/* .auctionCard .acActBtn .acTimer h6 span {
    padding-inline-end: 10px;
} */

.auctionCard .acActBtn .acTimer p {
    color: #646464;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
}

.auctionCard .pvTimerView {
    /* display: block; */
    width: 100%;
}

.auctionCard .acTimer > h6 {
    padding: 15px;
    color: #2e2e2e;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.auctionCard .pvTimerView h6 {
    text-align: center;
    /* display: flex; */
    align-items: center;
    margin-right: 0 !important;
    /* padding-left: 25px; */
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    /* border-top: 1px solid #f2f2f2; */
}

.auctionCard .pvTimerView h6 span {
    width: 50px;
    margin: 0;
}

/* AUCTION CARD END */

/* LIST */

.productCardList {
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
    align-items: center !important;
    width: 100%;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    border-radius: 5px;
}

.productCardList .psListLt {
    width: 75%;
    border-right: 1px solid #e4e4e4;
    margin-inline-end: 20px;
}

.productCardList .listLotDetails {
    margin-bottom: 20px;
}

.productCardList .listLotDetails {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 15px;
}

.productCardList .timerListView {
    font-size: 15px;
    text-align: center;
    margin-bottom: 0;
}

.productCardList .timerListView .material-icons-outlined {
    margin: 0 auto 5px;
}

.productCardList .timerListView span:last-child {
    width: 100%;
    display: block;
    color: var(--colorRed);
    font-weight: 500;
}

.productCardList .pclImg {
    width: 200px;
    position: relative;
    margin-inline-end: 20px;
    cursor: pointer;
}

.productCardList .pclImg img {
    width: inherit;
    display: block;
    height: 210px;
    object-fit: cover;
    border-radius: 3px;
}

.productCardList .pclImg .favoriteCheck {
    position: absolute;
    top: 0;
    right: 0;
}

.productCardList .listContent {
    width: 100%;
    text-align: start;
    margin-inline-end: 20px;
}
.productCardList .listContent .customNotesBox .MuiInputBase-input {
    border-radius: 1px !important;
    height: 50px;
}

.productCardList .listActBtn {
    width: 25%;
}

.productCardList .listContent .listProdTitle {
    font-size: 16px;
    color: #232323;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.productCardList .listActBox {
    width: 30%;
}

.productCardList > p {
    font-size: 14px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.productCardList .listBidInfo h6 {
    margin: 0;
    font-size: 15px;
    color: #373737;
    /* padding-inline-end: 25px; */
    min-width: 120px;
}

.productCardList .listTimer h6 {
    width: 100%;
    font-size: 16px;
    color: var(--colorRed);
    margin-bottom: 5px;
}

.productCardList .listTimer p {
    margin: 0;
    font-size: 15px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo p {
    margin: 0;
    font-size: 14px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo .retailPrice {
    color: var(--textColor);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
    margin-bottom: 0px;
    text-align: right;
}

.productCardList .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.productCardList .listBidInfo {
    margin-top: 5px;
    gap: 16px;
}

.productCardList .pvOfferZone p {
    margin-bottom: 0;
}

.productCardList .pvOfferZone .disCnut {
    color: var(--primColor);
}

.productCardList .pvOfferZone {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 10px;
}
.productCardList .pvOfferZone h6 {
    font-weight: 500;
    min-width: 120px;
    margin-bottom: 0;
}
.productCardList .pvOfferZone p > s {
    margin-right: 8px;
}

.productCardList .listTimer p span {
    padding-inline-end: 5px;
}

.productCardList .listBidBox {
    margin: 15px 0;
}

.productCardList .listBidBox .biddingCnt {
    flex-wrap: wrap;
}

.productCardList .listBidBox .biddingCnt > div {
    width: 100%;
}

.productCardList .listBidBox .customInput .MuiOutlinedInput-notchedOutline {
    border-color: #e6e6e6;
}

.productCardList .listBidBox .customInput {
    width: 100%;
    margin-bottom: 10px;
}

.productCardList .listBidBox .customInput .MuiOutlinedInput-root {
    border-radius: 0px;
}

.productCardList .listBidBox .primButton .MuiButtonBase-root,
.productCardList .listBidBox .primButton {
    color: #fff;
    border-radius: 0px;
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
}

.productCardList .listBidBox .primButton,
.productCardList .listBidBox .secButton {
    width: 100%;
}

.productCardList button.bidDetails {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

/* LIST END */

/* CART ITEM */

.cartItem {
    background: #fff;
    padding: 10px;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #d7dadc;
}

.cartItem .itemInfo {
    width: 75%;
}

.cartItem .pclImg {
    width: 100%;
    max-width: 150px;
    position: relative;
    margin-inline-end: 20px;
}

.cartItem .pclImg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

.cartItem .listContent {
    width: max-content;
    text-align: start;
    margin-inline-end: 20px;
}

.cartItem .listActBtn {
    width: 25%;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: #0e131f;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
}

.cartItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 15px;
    margin: 0;
    line-height: 25px;
    display: flex;
}

.cartItem .listContent .listLotInfo h5:nth-child(2) {
    padding-inline-start: 20px;
    border-left: 1px solid #ccc;
    margin-inline-start: 20px;
}

.cartItem .listContent .listLotInfo span:first-child {
    width: 90px;
    display: inline-block;
}

.cartItem .listContent .listLotInfo span:last-child {
    color: var(--secColor);
    font-size: 14px;
    font-weight: 700;
    padding-inline-start: 5px;
    display: inline-block;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-inline-end: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-inline-end: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartItem button.removeCart {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

.cartItem .updateCartQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
}

.cartItem .updateCartQuantity .customInput .MuiFormControl-root .MuiOutlinedInput-root {
    width: 120px;
    padding: 5px 35px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border-radius: 50px;
    text-align: center;
    height: 40px;
}

.cartItem .updateCartQuantity input:focus {
    outline: 0;
}

.cartItem .updateCartQuantity input {
    padding: 0;
    text-align: center;
}

.cartItem .updateCartQuantity button {
    min-width: max-content;
    position: absolute;
    top: 3px;
    z-index: 10;
    color: var(--primColor);
}

.cartItem .updateCartQuantity button:first-child {
    left: 10px;
    border-radius: 50px;
}

.cartItem .updateCartQuantity button.Mui-disabled {
    color: #ccc;
}

.cartItem .updateCartQuantity button:last-child {
    right: 10px;
    border-radius: 50px;
}

/* CART ITEM END */

/* SIMILAR ITEMS */
.similarItem {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
}

.similarItem .pclImg {
    width: 25%;
    margin-inline-end: 30px;
}

.similarItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.similarItem .listContent {
    text-align: start;
    width: 70%;
}

.similarItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
    margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
    margin: 45px 0 5px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
    width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
    width: 100%;
    height: 100%;
    max-width: 350px;
}

.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

/* .bidHistoryModal .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
} */

/* .bidHistoryModal table thead tr th:first-child,
.bidHistoryModal table tbody tr td:first-child {
    width: 55%;
} */

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
    text-align: start;
    white-space: nowrap;
}

.customModal .bidHistoryModal .modal-dialog.modal-lg {
    max-width: 650px;
}

.productCardGrid .moreInfo button,
.productCardList .moreInfo button {
    color: var(--textColor);
    text-transform: initial;
}

.productCardGrid .moreInfo button .material-icons,
.productCardList .moreInfo button .material-icons {
    padding-inline-start: 5px;
}

/*DOWNLOAD CARD*/
.gridCard {
    width: 100%;
    box-shadow: 0 0 10px #00000026;
    background: white;
    height: 320px;
}

.gridCard .pcgImg img {
    width: 100%;
    height: 240px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 96%);
    object-fit: cover;
    object-position: top;
}

.gridCard .pcgImg {
    width: 100%;
    height: 240px;
    position: relative;
}

.gridCard .pdtDtls {
    padding: 10px 20px;
}

.gridCard .pdtDtls .pdtName {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: var(--displayFont);
}

.gridCard .pdtDtls .cstDtls div:first-child {
    border-right: 1px solid #c1c1c1;
}

.gridCard .pdtDtls .cstDtls div p {
    font-weight: bold;
    font-size: 1.125rem;
}

.gridCard .pdtDtls .cstDtls .bdCnt {
    font-size: 0.8rem;
    color: rgb(59, 152, 228);
    text-decoration: underline;
}

.gridCard .pdtDtls {
    position: relative;
}

.dnldGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.productCardGrid .cardFootSec .retailPrice {
    color: var(--textColor);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
    margin-bottom: 3px;
    text-align: right;
}

/* grid layout New changes */

.productCardGrid .tickot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.productCardGrid .tickot .icon {
    font-size: 18px;
    color: var(--colorRed);
    margin-right: 3px;
}

.productCardGrid .tickot .pvTimerView h6 span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: var(--colorRed);
    margin: 0;
}

.productCardGrid .tickot .pvTimerView h6 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    color: var(--colorRed);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
    flex-direction: row-reverse;
    margin-right: 0;
}

.productCardGrid .gvBidding .biddingCnt .primButton {
    margin-top: 10px;
}

.productCardGrid .cardFootSec {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* padding: 0 9px; */
}

.productCardGrid .cardFootSec .currentBid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    color: #fff;
    width: 100%;
    margin-top: 8px;
    padding: 10px;
}

.productCardGrid .biddingCnt {
    margin-top: 10px;
}

.productCardGrid .biddingCnt .primButton,
.productCardGrid .biddingCnt {
    width: 100%;
}

.productCardGrid .biddingCnt .primButton .MuiButtonBase-root {
    color: #fff;
    border-radius: 0px;
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
}

.productCardList .status,
.productCardGrid .status {
    color: var(--colorBlue);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
}

.productCardGrid .pcgImg {
    /* height: 100%; */
    /* height: 175px; */
    margin-bottom: 3px;
}

.productCardGrid .gridProdTitle {
    color: #232323;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    letter-spacing: 0.3px;
    margin-top: 0px;
    margin-bottom: 5px;
    min-height: fit-content;
    height: fit-content !important;
}

.productCardGrid .pcgImg img {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.productCardList .location,
.productCardGrid .location {
    color: var(--accentColor);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    min-height: 19px;
    display: block;
}

.productCardList .sku_count,
.productCardGrid .sku_count {
    color: black;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    min-height: 19px;
    display: block;
}

.gridDescCnt {
    /* padding: 0 8px; */
}
.search .gridDescCnt.custGrids {
    height: auto;
}
.gridDescCnt.custGrids {
    height: inherit;
    margin-top: 6px;
}

.productCardList .condition,
.productCardGrid .condition {
    color: white;
    background-color: var(--primColor);
    cursor: pointer;
    border-radius: 4px;
    margin-top: 8px;
    padding: 5px 5px;
    font-size: 11px;
    width: fit-content;
}

.productCardList .pclImg .favoriteCheck label span,
.productCardGrid .pcgImg .favoriteCheck label span {
    font-size: 16px;
}

.productCardGrid .pcgImg .favoriteCheck label {
    font-size: 14px;
    color: var(--textColor);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.productCardList .pclImg .favoriteCheck,
.productCardGrid .pcgImg .favoriteCheck {
    background-color: #fff;
    border: 1px solid #efefef;
    width: 29px;
    height: 29px;
    top: 8px;
    right: 8px;
}

.customBiddingField .customInput {
    margin-bottom: 0;
}

.customBiddingField .customInput .MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0;
}

.advertisement_card {
    background: #232f3e;
    padding: 30px;
    border-radius: 12px;
    margin-bottom: 20px;
    text-align: left;
    margin: 10px 0;
    height: 100%;
    border-radius: 2px;
}

.searchResults.Grid .advertisement_label2 {
    width: 100%;
    max-width: 100% !important;
}
.searchResults.Grid .advertisement_card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
}

.advertisement_label2 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    max-width: calc(100% - 22%);
    line-height: 22px;
    text-transform: capitalize;
}
.advertisement_label1 {
    color: #ffffff;
    font-size: 28px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.baDge {
    padding: 6px 6px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    /* margin-left: 10px; */
    margin: 6px 0px;
    font-weight: 600;
    width: fit-content;
    white-space: nowrap;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.oB {
    background-color: #69b3fe;
}

.sD {
    background-color: #008000;
}

.dm {
    background-color: #f34770;
}

.sv {
    background-color: #e26124;
}

.Nw {
    background-color: #7cb02d;
}

.listBidInfo.infoGrid {
    display: grid;
    grid-template-columns: 1fr auto;
}
.listBidInfo.infoGrid.infoGridCust {
    grid-template-columns: 1fr auto;
}

.conditionModal span {
    padding: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.conditionModal .ssmHead svg {
    width: 16px;
}

.dentPro {
    color: green;
    cursor: pointer;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 0;
}

.pvRetailTimeDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.pvRetailDiv {
    color: #2f6baf;
    text-align: right;
    justify-content: flex-end;
    display: flex;
    margin-bottom: 0;
    font-size: 12px;
}

button.moreInfoGridBtn {
    width: 100%;
    padding: 10px 0 !important;
    margin-top: 16px !important;
    background: #252f3d !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    text-transform: none;
    height: 65px;
    border-radius: 1px !important;
}

.pcgImg .pcQtyCnt {
    position: absolute;
    bottom: 0;
    background: white;
    font-size: 12px;
    display: flex;
    gap: 4px;
    padding: 2px 14px 2px 7px;
    color: #232323;
    font-weight: 400;
}

.biddingCnt .primButton button {
    height: 65px !important;
    border-radius: 1px !important;
}

button.pastInfoGridBtn.moreInfoGridBtn {
    margin-top: 2px !important;
    background-color: white !important;
    color: black !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    height: fit-content !important;
}
button.pastInfoGridBtn.moreInfoGridBtn .MuiButton-label {
    display: flex;
    gap: 4px;
}
button.pastInfoGridBtn.moreInfoGridBtn .material-icons {
    font-size: 28px;
}

.pvDicountDiv {
    color: #2f6baf;
}

.pvDicountDiv span {
    /* color: #2f6baf; */
    margin: 0 2px;
}

.listBidStatus {
    position: absolute;
    top: 0px;
    left: 5px;
    transform: translateY(-50%);
}

.listBidStatus h4 {
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    color: white;
    padding: 6px 16px;
    margin-bottom: 0px;
    font-size: 12px !important;
}

.biddingCnt button .no-border {
    border: 0;
}
/* responsive */

@media (max-width: 991px) {
    .productCardList {
        flex-wrap: wrap;
    }
    .productCardList .psListLt {
        width: 100%;
        border-bottom: 1px solid #e4e4e4;
        border-right: 0;
        margin-inline-end: 20px;
    }
    .productCardList .listActBtn {
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .cartItem {
        flex-wrap: wrap;
        width: 48%;
    }
    .productCardList .pclImg {
        width: 100%;
        margin-inline-end: 0;
    }
    .productCardList .psListLt {
        flex-direction: column;
        align-items: start !important;
        margin: 0;
    }

    .cartItem .itemInfo {
        width: 100%;
        flex-wrap: wrap;
    }

    .cartItem .pclImg {
        max-width: none;
        margin-inline-end: 0;
    }

    .cartItem .pclImg img {
        height: 200px;
    }

    .cartItem .listContent {
        width: 100%;
        margin-inline-end: 0;
    }

    .cartItem .listActBtn {
        width: 100%;
    }
}

@media (max-width: 645px) {
    button.moreInfoGridBtn {
        height: 40px;
    }
    .productCardGrid .gridBidBox,
    .productCardGrid .gridBidInfo,
    .productCardGrid .gridLotDetails p {
        height: auto;
    }

    .gridViewSkeleton {
        max-width: none;
    }
}

@media (max-width: 500px) {
    .auctionCard {
        flex-wrap: wrap;
    }

    .auctionCard .auctionCardLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .auctionCard .acImg {
        max-width: initial;
        margin-inline-end: 0;
    }

    .auctionCard .acContent {
        width: 100%;
        margin-inline-end: 0;
    }

    .auctionCard .acActBtn {
        width: 100%;
    }

    .auctionCard .acTimer {
        /* flex-wrap: nowrap !important; */
        /* justify-content: flex-start !important; */
        margin: 10px 0;
    }

    /* .auctionCard .acActBtn .acTimer h6 {
        justify-content: flex-start;
        width: max-content;
    } */
    .auctionCard .pvTimerView {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
    }

    .auctionCard .pvTimerView h6 {
        padding-left: 0;
        font-size: 16px;
    }

    .auctionCard .pvTimerView h6 span {
        width: auto;
        padding-right: 10px;
    }

    .auctionCard .acActBtn .acTimer p {
        margin: 0;
        margin-inline-start: 10px;
    }

    .auctionCard .acContent h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .auctionCard .acContent p {
        font-size: 13px;
        margin-bottom: 2px;
    }

    .cartItem .pclImg img {
        height: 150px;
    }

    .cartItem .itemInfo {
        width: 100%;
    }

    .productCardGrid .gridProdTitle {
        font-size: 16px;
    }

    /* .productCardGrid .gridTimer h6, */
    .productCardGrid .gridBidInfo h6 {
        font-size: 13px;
    }

    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        width: 100%;
    }

    .productCardGrid .gridBidBox .customInput,
    .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }

    /* .productCardGrid .gridBidBox .customInput input {
        height: 100%;
    } */
    .productCardGrid .gridBidBox .primButton,
    .productCardGrid .gridBidBox .secButton {
        width: 49%;
    }

    .similarItem .listContent .listProdTitle {
        font-size: 15px;
    }

    .similarItem .pclImg {
        width: 30%;
        margin-inline-end: 10px;
    }

    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }

    .cartItem .listContent .listLotInfo h5 {
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 450px) {
    .auctionCard .acContent h2 {
        font-size: 14px;
    }

    .auctionCard .acContent p {
        font-size: 12px;
    }

    .auctionCard .acContent {
        margin-bottom: 10px;
    }
}

/* @media (min-width: 1440px) {
  .productCardGrid {
    width: 24%;
  }
} */
