.thumbsContainer,
.thumbsContainer .reorderCnt {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
}

.thumbsContainer .thumb {
    margin-inline-end: 10px;
    margin-bottom: 10px;
}

.thumbsContainer .thumb img {
    height: 100px !important;
    width: 100px !important;
    border-radius: 5px;
    object-fit: cover;
    border: 1px solid #e3e3e3;
}

.thumbsContainer .thumb .thumbCnt {
    position: relative;
}

.thumbsContainer .thumb .thumbCnt {
    position: relative;
}

.thumbsContainer .thumb .thumbCnt .fileActions {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 5px;
    background: #5d5d5dc9;
    width: 100%;
    height: 100%;
    transition: 200ms all ease-in-out;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbsContainer .thumb .thumbCnt:hover .fileActions {
    visibility: visible;
    opacity: 1;
}

.thumbsContainer .thumb .thumbCnt .editBtn {
    margin-inline-start: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.thumbsContainer .thumb .thumbCnt .cancelBtn {
    margin-inline-end: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.thumbsContainer .thumb .thumbCnt .addBtn {
    margin-inline-start: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.thumbsContainer .thumb .thumbCnt .viewBtn {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.thumbsContainer .thumb .thumbCnt .addBtn:hover {
    color: #a3f2a3;
}

.thumbsContainer .reorderCnt .thumb .thumbCnt {
    cursor: move;
    user-select: none;
}

.thumbsContainer .reorderCnt .thumb .thumbCnt:hover::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
}

.thumbsContainer .reorderCnt .thumb .thumbCnt:hover::after {
    font-family: 'Material Icons';
    position: absolute;
    content: '\e89f';
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    color: #919191;
}

.thumbsContainer .reorderCnt .thumb .thumbCnt .fileActions {
    top: initial;
    bottom: 0;
    background: #262626d4;
    width: 100%;
    height: 35px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.thumbsContainer .reorderCnt .thumb .MuiBadge-root {
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    font-size: 12px;
    border: 1px solid #ccc;
    font-weight: 600;
    color: #1f1f1f;
    position: absolute;
    top: 5px;
    left: 5px;
}

.cardDetails .sfInpBox {
    height: 70px;
    margin-bottom: 30px;
}

.thumbsContainer .thumb .thumbCnt .editBtn:hover {
    color: #ffa87a;
}

.thumbsContainer .thumb .thumbCnt .cancelBtn:hover {
    color: #ffa87a;
}

.thumbsContainer .thumb .thumbCnt .viewBtn:hover {
    color: #8dd7ff;
}

.notAvailable {
    color: #b7b7b7 !important;
}

.thumbsContainer .thumb .thumbCnt .defaultThumb {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

.thumbsContainer .thumb .thumbCnt .defaultThumb .material-icons {
    font-size: 60px;
    color: #ccc;
}

.dropzone {
    border: 1px dashed #c9c5d7;
    padding: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #737373;
    flex-wrap: wrap;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
}

.dropzone h4.uploadClass {
    width: 100%;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    margin-top: 8px;
}

.RadioBox .MuiFormControlLabel-root {
    margin-bottom: 0;
}

.customCheckbox .MuiFormHelperText-root,
.checkboxError .MuiFormHelperText-root,
.validationError,
.RadioBox .MuiFormHelperText-root {
    text-align: center;
    color: #f44336;
    font-size: 0.75rem;
}

.MuiFormHelperText-root.Mui-error {
    white-space: pre-wrap;
}

.checkboxError {
    width: 100%;
}

.checkboxError .MuiFormHelperText-root {
    text-align: left;
    text-indent: 50px;
}

.checkboxError p,
.RadioBox .MuiFormHelperText-root {
    text-align: start;
}

.checkboxHeading {
    width: 100%;
    font-size: 18px;
    color: #000;
    margin-bottom: 15px !important;
}

.password-strength-meter .password-strength-meter-progress {
    width: 100%;
}

.customizedIcon {
    border-radius: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #4d4e56;
    background: transparent;
    position: relative;
}

.customizedIcon.checkedIcon:after {
    position: absolute;
    content: '';
    left: 7px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid #4d4e56;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.adrrAct {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.adrrAct .primButton,
.adrrAct .secButton {
    width: 150px;
}

.adrrAct .primButton:last-child {
    margin-left: 10px;
}

.customDialog .cdTop .ttleCntnr h4 {
    text-align: left;
}

.customDialog .cdTop .tit {
    font-weight: 700;
}

.customDialog .cdTop {
    display: flex;
    justify-content: space-between;
}

.customDialog .cdTop.lisitnModal .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
}

.customDialog .cdTop.lisitnModal {
    flex-direction: column;
    padding: 16px 24px;
}

.customDialog .btnStack {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.customDialog .icnCrclCntnr {
    display: flex;
    word-break: break-word;
    flex-wrap: wrap;
    padding: 25px 0;
}

.customDialog .cdTop .MuiDialogTitle-root {
    line-height: initial;
}

.customDialog .cdTop .closeBtn {
    width: 45px;
    min-width: 45px;
    height: 45px;
    min-width: initial;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 15px;
}

.verifyPhone .actionWrapper.secondaryActions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.addCaptionModal .MuiPaper-root {
    max-width: 1088px;
    width: 100%;
    position: relative;
    padding: 30px 25px;
    border-radius: 15px;
}

.addCaptionModal .infoModalTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.addCaptionModal .infoModalTitle h4 {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
}

.addCaptionModal .closeIcon {
    margin-left: auto;
    color: #919191;
    min-width: max-content;
    margin-top: -20px;
}

.addCaptionModal .btnWrpr {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.addCaptionModal .btnWrpr .secButton {
    margin-right: 15px;
}

.addCaptionModal .btnWrpr .secButton,
.addCaptionModal .btnWrpr .primButton {
    min-width: 160px;
}

.otpInput {
    margin-bottom: 15px;
    justify-content: center;
}

.otpInput input {
    width: 60px !important;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
}

.verifyPhone.customDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 700px;
}

.verifyPhone.customDialog .MuiDialogContent-root {
    text-align: center;
}

.verifyPhone .phVer {
    display: block;
    margin-bottom: 25px;
}

.verifyPhone .primButton {
    width: max-content;
    margin: 0 auto;
    min-width: 200px;
}

.verifyPhone .secondaryActions button {
    margin-inline-end: 0 !important;
}

.verifyPhone .vpbTitle {
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 35px;
}

.verifyPhone .vpbTitle span {
    font-weight: 600;
}

.cnfModal .MuiPaper-root.MuiDialog-paper {
    padding: 30px;
}

.cnfModal .cnfTitle {
    text-align: center;
    font-size: 20px;
    color: #333;
    font-weight: 600;
    margin: 20px 0 15px;
}

.cnfModal .cnfSubTitle {
    text-align: center;
    color: rgb(109, 109, 109);
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 20px;
}

.cnfModal .secButton {
    margin: 45px auto 0;
    width: max-content;
    min-width: 200px;
}

.cnfModal .material-icons-outlined {
    text-align: center;
    display: block;
    margin: 0 auto 15px;
    font-size: 50px;
    color: green;
}

.customToggle .MuiFormControlLabel-root {
    margin-inline-end: 0;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.customToggle .MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: #333;
    font-weight: 600;
}

.customToggle .MuiSwitch-colorSecondary.Mui-checked {
    color: #0a940a;
}

.customToggle .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #0a940a;
}

.datePickerLabelCnt {
    align-items: center;
    margin-bottom: 15px;
}

.datePickerLabelCnt button {
    text-transform: inherit;
    padding: 3px 12px;
    font-size: 12px;
    margin-left: 15px;
}

.datePickerLabelCnt button .material-icons {
    font-size: 18px;
}

.datePickerLabelCnt label {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
}

.cardActCnt {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 15px;
    margin-top: 20px;
}

.paymentModalAct {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.removeAddnBtn {
    min-width: max-content !important;
    color: #e77575 !important;
    background: #ffe7e7;
    border-radius: 50% !important;
    width: 35px;
    height: 35px;
    margin-left: 10px !important;
}

.removeAddnBtn .material-icons-outlined {
    font-size: 20px;
}

.removeCart {
    color: #e77575 !important;
}

.cardDetails.activeCard .savedAddressCard {
    margin-left: 0;
}

.cardDetails.activeCard {
    background: #ecf6ff;
}

.cardDetails.activeCard .cardDetails h6 {
    color: var(--primColor);
}

.cardSelectionError {
    font-size: 15px;
    color: #e35d5d;
    justify-content: center !important;
    width: 100%;
    margin-top: 5px;
}

.skyFlowCardPopup .sfInpBox {
    height: 70px;
    margin-bottom: 30px;
}

.productFullscreenModal .fspLoader.noGridLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.bpoint-container {
    text-align: left;
}

.filterCount {
    width: 24px;
    height: 24px;
    background: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 50%;
    color: var(--primColor);
    margin-left: 2px;
    position: absolute;
    top: 10px;
    right: -14px;
    border: 1px solid #e4e4e4;
}

.customizedUploader .dropzone {
    padding: 11px;
}

.customizedUploader .dropzone h6 {
    display: none;
}

.disabledDropzone .dropzone {
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.7;
    background: #e9e9e9;
}

.customSelect .nonNative .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
    z-index: 1;
    background: #fff;
    padding: 0 3px;
}

.MuiSelect-root {
    text-align: left;
}

.dashboardInner.myAccount .deleteAccCnt {
    /*margin-top: 30px;
    */
    padding: 5px 0;
    border-radius: 8px;
    width: fit-content;
    margin-left: auto;
    border: 1px solid #ff9898;
}

.dashboardInner.myAccount .deleteAccount {
    color: #dc0505;
    margin-left: auto;
    display: flex;
    font-size: 12px;
}

.validationErrorDialog ol li {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 5px;
}

.validationErrorDialog ol li span {
    padding-left: 8px;
    font-weight: 400;
    color: #4f4f4f;
}

/* charity css */
.indivualCharityContainer {
    margin: 25px auto;
    padding-top: 25px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-bottom: 25px;
}
.indivualCharityContainer label {
    font-weight: 600;
    text-transform: capitalize;
}
.indivualCharityContainer .chvlus .logo {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 6px;
}
.indivualCharityContainer .chvlus {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #212529;
}

/* charity css end */

.avatarUploader {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ececec;
    box-shadow: 0px 3px 10px #cccccc70;
    position: relative;
}

.avatarUploader .thumbAvatar,
.avatarUploader .thumbInner,
.avatarUploader .thumbCnt,
.avatarUploader .thumbCnt img {
    width: inherit;
    height: inherit;
}

.avatarUploader .thumbAvatar {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    overflow: hidden;
}

.avatarUploader .thumbCnt img {
    object-fit: cover;
}

.avatarUploader .thumbCnt .fileActions {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 5px;
    background: #5d5d5dc9;
    width: 100%;
    height: 100%;
    transition: 200ms all ease-in-out;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatarUploader .thumbCnt:hover .fileActions {
    visibility: visible;
    opacity: 1;
}

.avatarUploader .thumbCnt .editBtn {
    margin-inline-start: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.avatarUploader .thumbCnt .cancelBtn {
    margin-inline-end: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.avatarUploader .thumbCnt .addBtn {
    margin-inline-start: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.avatarUploader .thumbCnt .viewBtn {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.avatarUploader .thumbCnt .addBtn:hover {
    color: #a3f2a3;
}

@media (max-width: 767px) {
    .verifyPhone.customDialog .MuiPaper-root.MuiDialog-paper {
        min-width: fit-content;
        max-width: 700px;
    }
}

@media (max-width: 600px) {
    .verifyPhone .vpbTitle span.phne {
        word-break: keep-all;
        white-space: nowrap;
    }
}

@media (max-width: 500px) {
    .otpInput input {
        width: 40px !important;
        height: 40px;
    }
}
