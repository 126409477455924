* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-family: var(--fontFamily);
}

body {
    margin: 0;
    font-family: var(--fontFamily), sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor);
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.initalLoadingCnt {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--primColor);
    font-weight: 500;
}

.customContainer {
    padding-inline-start: 30px !important;
    padding-inline-end: 30px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-left: auto !important;
    margin-right: auto !important;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label,
.MuiFormLabel-root {
    font-family: var(--fontFamily) !important;
    /* 'Segoe UI', 'Open Sans', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 30px;
    /* width: 100%; */
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 280px;
    width: 100%;
    margin-inline-end: 25px;
}

.search .searchLt {
    position: sticky;
    top: 10px;
}

.dashboard .dashboardLt .sideNav {
    position: sticky;
    top: 10px;
}

.search .searchRt,
.dashboard .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 305px);
    overflow: hidden;
}

.search .searchRt {
    max-width: calc(100% - 0px);
}

.search .searchResults.Grid,
.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.search .searchMiscFilters .customSelect .MuiFormControl-root.MuiTextField-root {
    margin-top: 5px;
}

.search .searchMiscFilters .customSelect,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 25px;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-inline-end: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

.dpTable table {
    color: #212529;
}

.dpTable table thead th {
    background: #343a40;
    color: #fff;
}

.dpTable table .MuiFormControlLabel-root {
    margin: 0;
}

.dpTable table td.credit {
    color: #4caf50;
    font-weight: 500;
    display: flex;
}

.dpTable table td.debit {
    color: #f44336;
    font-weight: 500;
    display: flex;
}

.dpTable table td > .material-icons {
    padding-inline-end: 5px;
}

.dpTable .table th {
    border: none;
}

.dpTable .table td,
.dpTable .table th {
    vertical-align: middle;
}
.notifications .dpTable .table tr:nth-last-child(1) td {
    border-bottom: 0px solid #fff;
}

.notifications .dpTable .table td {
    border-bottom: 1px solid #dee2e6;
}

.searchSkeletonBody {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.searchSkeletonBody > div {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.material-icons.rtl,
.rtlImage {
    transform: rotateY(180deg);
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    min-height: 300px;
}

.userInitials.small {
    width: 30px;
    height: 30px;
    font-size: 14px;
    background: #3d3d3d;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInitials.large {
    width: 50px;
    height: 50px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    background: #f1f3f6;
    border-radius: 50%;
    color: #9ca8b9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.regDivider {
    margin-bottom: 35px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 60vmax !important;
    max-width: 1000px !important;
    padding: 20px;
}

.pvTimerView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pvTimerView h6 {
    font-size: 20px;
    font-weight: 600;
    color: #db3e3e;
    margin-bottom: 0;
    text-wrap: nowrap;
    word-break: keep-all;
}

.pvTimerView h6:not(:last-child) {
    margin-right: 25px;
}

.pvTimerView h6 span {
    display: block;
    font-size: 12px;
    font-weight: 700;
    color: #b5b5b5;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.savedBankCnt {
    display: grid;
    margin-top: 25px;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
}

.savedBankCnt .savedBank {
    padding: 15px;
    text-align: left;
    background: #ebebeb;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin: 0;

    background-image: linear-gradient(to right, #b0fbff 0%, #4facfe 100%);
}

.savedBankCnt .savedBank .material-icons {
    font-size: 45px;
    color: var(--primColor);
}

.savedBankCnt .savedBank h4 {
    font-size: 16px;
    margin: 20px 0 15px;
    font-weight: 600;
    color: #333;
}

.savedBankCnt .savedBank h5 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 600;
    color: #333;
    letter-spacing: 4px;
}

.savedBankCnt .savedBank.addNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 2px dashed #ddd;
    cursor: pointer;
}

.savedBankCnt .savedBank.addNew .material-icons {
    margin-bottom: 20px;
    color: #abaeb3;
}

.savedBankCnt .savedBank.addNew h6 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #275168;
}

.savedBankCnt .savedBank h4 span,
.savedBankCnt .savedBank h5 span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: var(--primColor);
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.savedBankCnt .savedBank .edtBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
}

.savedBankCnt .savedBank .dltBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: #dc3545;
}

.fullScreenPopup .MuiToolbar-root.fspToolbar {
    max-width: 1600px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.fullScreenPopup .MuiToolbar-root.fspToolbar .MuiTypography-root {
    margin-left: 0;
}

/* .slick-slider .slick-list .slick-slide {
    width: max-content !important;
} */

.customMegamenu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    height: fit-content;
    color: #232323;
    gap: 15px;
    grid-row-gap: 5px;
}

.megaMenu button:hover {
    color: var(--primColor) !important;
}
.megaMenu button:hover .menuObject {
    filter: brightness(0) saturate(100%) invert(43%) sepia(51%) saturate(500%) hue-rotate(79deg)
        brightness(90%) contrast(90%);
}

.customMegamenu li {
    list-style-type: none;
}
.customMegamenu li:hover {
    background-color: #0a0a0a12;
}

.customMegamenu h5 {
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    /* width: fit-content; */
    padding: 10px 0px 8px 0px;
    margin-bottom: 0;
    margin: 0px 15px 0px 15px;
}
.customMegamenu h6 {
    display: inline;
}

.customMegamenu.allLoc {
    grid-template-columns: 1fr;
}

.customMegamenu ul {
    list-style: none;
    padding: 0;
    margin: 6px 15px 15px 15px;
}

.customMegamenu ul li {
    /* padding: 10px 0px 10px 0px; */
}
.customMegamenu ul li:hover {
    color: var(--primColor);
    text-decoration: underline;
    background: transparent;
}

.customMegamenu li h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    cursor: pointer;
    padding-bottom: 0px;
}

.customMegamenu.allLoc li:not(:nth-last-child(1)) {
    margin-bottom: 5px;
}

.customMegamenu li a {
    font-size: 14px;
    font-weight: 400;
    color: #232323;
}

.customMegamenu li a:hover {
    color: var(--primColor);
}

.bidBoxCnt {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.bidBoxCnt input::placeholder {
    font-size: 18px;
}
.bidBoxCnt p {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 11px;
}
.bidBoxCnt .MuiFormHelperText-root.Mui-error {
    display: none;
}
.biddingAlignCnt {
    flex-direction: column-reverse;
    gap: 8px;
}

.bidFormShow.bidForm form > .biddingCnt > .biddingCnt {
    width: 100%;
}

.biddingInputBtnCnt {
    display: flex;
    gap: 12px;
    flex-direction: row;
    height: 100%;
    flex-direction: column;
}

.bidFormShow .biddingInputBtnCnt {
    flex-direction: row;
}
.biddingInputBtnCnt .customBiddingField {
    width: 100%;
}
.biddingInputBtnCnt .primButton.small button {
    height: 65px !important;
    background: white;
    border: 1px solid #252f3d;
    border-radius: 1px !important;
}
.biddingInputBtnCnt .primButton.small button .MuiButton-label {
    color: #252f3d;
}

.MuiPopover-paper {
    top: 68px !important;
}
.qrPopUpDialog .qrUserName {
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
    align-items: center;
}
.qrPopUpDialog .qrUserName h5 {
    margin: 0 !important;
}
.qrPopUpDialog .qrUserName h5:nth-child(2) {
    font-size: 18px;
}
.qrPopUpDialog .qrUserName h5:nth-child(1) {
    color: var(--primColor);
    font-size: 18px;
}

.profileEditICon {
    cursor: pointer;
}

.customNotesBox .customInput {
    margin-bottom: 0;
}

.customNotesBox {
    gap: 5px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 15px;
}
.customNotesBox button {
    padding: 0;
}
.customNotesBox p {
    color: var(--primColor);
    margin-left: 4px;
    font-weight: 500;
}
.customNotesBox .material-icons {
    font-size: 19px;
}

.customNotesBox .customInput {
    min-width: 150px;
}

.customNotesBox .customInput input {
    background-color: #fff !important;
}

.quickBidNotEnable button {
    background-color: #565656 !important;
    backface-visibility: hidden;
    box-shadow: none !important;
    border: 0 !important;
}

.pvTabs .tPanel p,
.pvTabs .tPanel span {
    font-family: var(--fontFamily) !important;
    font-size: 16px;
}

.gridListToggle .active {
    color: var(--primColor);
}

.favoriteCheck input:checked + label {
    color: #ee5600;
}

.customDialog.biddingCstmDialog .cdTop {
    border-bottom: 1px solid #f2f2f2;
    position: relative;
    justify-content: center !important;
}

.customDialog.biddingCstmDialog .biddingPopTitleCnt {
    text-align: center;
}
.customDialog.biddingCstmDialog .biddingPopTitleCnt .material-icons {
    text-align: center;
    position: absolute;
    top: 16px;
    right: 24px;
    cursor: pointer;
}

.bidConfirmDetailsCnt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 16px;
}

.bidConfirmDetailsCnt > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: center;
}

.bidConfirmDetailsCnt > div label,
.bidConfirmDetailsCnt > div h5 {
    margin-bottom: 0px !important;
}

.bidConfirmDetailsCnt > div label {
    text-align: right;
}
.customDialog.biddingCstmDialog .bidConfirmDetailsCnt > div h5 {
    text-align: left;
    font-size: 16px;
}
.customDialog.biddingCstmDialog .bidConfirmDetailsCnt > div h5.bidLocation {
    color: #db3e3e;
}

.customDialog.biddingCstmDialog .actionWrapper {
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 32px;
}
.customDialog.biddingCstmDialog .actionWrapper button {
    width: fit-content;
}

@media (max-height: 640px) {
    .MuiPopover-paper {
        padding-bottom: 32px !important;
    }
}

@media (max-width: 1145px) {
    .search .searchLt,
    .dashboard .dashboardLt {
        max-width: 250px;
    }

    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-inline-start: 15px;
    }
}

@media (max-width: 1024px) {
    .biddingInputBtnCnt .primButton.small button {
        height: 50px !important;
    }
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff2e7;
        border-color: rgb(25 157 229 / 20%) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

input[value='']::-webkit-datetime-edit {
    color: transparent;
}

input:focus::-webkit-datetime-edit {
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.MuiDivider-root {
    /* background-color: rgb(149 208 242 / 55%) !important; */
}

@media (max-width: 1366px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }
}

@media (max-width: 992px) {
    .customMegamenu {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        overflow: hidden;
    }
    .customMegamenu div {
        width: 100%;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: calc(100vw - 10vw) !important;
    }
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }
}

@media (max-width: 767px) {
    .dashboard .dashboardInner > .searchMiscFilters {
        flex-wrap: wrap;
    }
    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin: 0 15px 0 0;
    }
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }

    /* .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    } */
}

@media (max-width: 479px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }

    .pagination-wrapper h6 {
        font-size: 14px;
    }
    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 100vw !important;
    }
    .pvTimerView h6 {
        font-size: 16px;
    }
}

@media print {
    .noPrint {
        display: none !important;
    }
}
